import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { convertDateToCustomFormat } from "../../utils/commonUtils";
export default function AppoinmentDashboardCard({ item,onClick }) {
  return (
    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} sx={{ p: "10px" }}>
      <Box
        sx={{
          p: "10px",

          fontWeight: "bold",
          height: "100px",
          borderRadius: "10px",

          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        }}
        onClick={()=>
        {
          console.log("Data ",item)
          onClick(item)
        }
        }
      >
        <Grid container>
          <Grid item xs={12}
          sx={{
            
          }}>
             
          </Grid>
          <Grid item xs={12} sx={{
            display:'flex', 
            alignItems:'center',
            justifyContent:'space-between'
          }}>
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px",
                fontSize:'bold'
                // fontWeight: "600",
              }}
            >
                  <Typography component={'span'} sx={{color: (theme) => theme.palette.secondary.main,fontWeight:600}}>{item?.name}</Typography>
            </Typography>  
             
          </Grid>
      
          <Grid item xs={12} sx={{
            display:'flex', 
            alignItems:'center',
            justifyContent:'space-between'
          }}>  
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px",
                // color: (theme) => theme.palette.primary.main,
                // fontWeight: "600",
                
              }}
            >
            <Typography component={'span'} sx={{fontWeight:600}}> {item?.address}</Typography>
            </Typography>
            
          </Grid>
           
          <Grid item xs={12} sx={{
            display:'flex', 
            alignItems:'center',
            justifyContent:'space-between'
          }}>  
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px",
                // color: (theme) => theme.palette.primary.main,
                // fontWeight: "600",
                
              }}
            > <Typography component={'span'} sx={{fontWeight:600}}>{item?.mobile}</Typography>
            </Typography>
            
          </Grid>
         
          <Grid item xs={12} sx={{
            display:'flex', 
            alignItems:'center',
            justifyContent:'space-between'
          }}>
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px",
                display: "flex",
                justifyContent:"left",
                alignItems: "center",
                // color: (theme) => theme.palette.primary.main,
                // fontWeight: "600",
              }}
            >
           Payment Percentage :  <Typography component={'span'} sx={{color: (theme) => theme.palette.primary.main,fontWeight:600}}>{item?.paymentPercentage}%</Typography>
            </Typography> 
          </Grid>
    
        </Grid>
      </Box>
    </Grid>
  );
}
