import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  Container,
  Grid,
  Box,
  Stack,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Modal,
} from "@mui/material";
// import "./test.css";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { bookApoinment } from "./asppoinmentApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { routePath } from "../../constants/routePath";
import { setSession } from "../../utils/commonUtils";
import { setCustomerAppointmentId } from "../../app/store/reducer";
// import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { getAlldetailSerice } from "../services/servicesListApi";
import EmployeeModal from "../../common/employeeModel";
import { addOffer, updateOffer } from "./apiFunction";
import ImageUploadButton from "../../common/imgUpload";
import { ModalHeader } from "../../common/modalHeader";
dayjs.extend(utc);
dayjs.extend(timezone);

const PopUp = ({ open, setOpen, data }) => {

  const struct = {
    billerId: "",
    customerName: "",
    mobileNumber: "",
    // otp: "",
    mailId: "",
    gender: "",
    service: "",
    date: "",
    hour: "",
    minute: "",
    status: "Unassigned",
    employeeId: "",
  }
  const [formData, setFormData] = useState({ ...struct });
  const handle = (e) => {
    e.preventDefault()
    if (data) {
      updateOffer({ message: formData.customerName, id: data?.id }, (data) => {
        alert('ioni')
      })
    }
    else {
      addOffer({ message: formData.customerName }, (data) => {
        alert('ioni')
      })
    }

  }
  useEffect(() => {
    if (open == true) {
      console.log("Data", data)

      if (data) {
        setFormData({ ...formData, customerName: data?.offerInformation })
      }
    }

else{
  setFormData(struct)
  }
  }, [open])

  return (
    <Modal
      open={open}
      onClose={() => { setOpen(false) }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "52%",
          overflowY: "auto",
          // height: "90vh",
          position: "absolute",
          left: "25%",
          top: "10%",
        }}
      >
        <ModalHeader    title={"Offer Message"} onClose={(data)=>{setOpen(data)}}/> 
        <Container>
          <Box sx={{ mt: 4, my: 5 }} display="flex" justifyContent="center">
            <form onSubmit={handle}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Enter Message"
                      name="customerName"
                      value={formData.customerName}
                      onChange={(e) => {
                        setFormData({ ...formData, customerName: e.target.value })
                      }} 
                      required
                      multiline // Makes the TextField act as a textarea
                      rows={4} // Number of rows (you can adjust this)
                      InputLabelProps={{ className: "mulish" }}
                      InputProps={{ classes: { input: "mulish" } }}
                    />
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "#3695B6",
                      padding: "14px",
                      fontSize: "16px",
                    }}
                  >
                    save

                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>{" "}

      </Box>
    </Modal>
  );
};

export default PopUp;
