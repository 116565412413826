import React from 'react';
import './TableComponent.css';
import { useTheme } from '@emotion/react';

const TableComponent = ({ stocks = [] }) => {
    const theme=useTheme()
  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr style={{
            color:'white',
                backgroundColor:"#3695B6",
                
            }}>
            <th >Item Name</th>
            <th>Alert Value</th>
            <th>Available</th>
          </tr>
        </thead>
        <tbody>
          {stocks.map((row) => (
            <tr
              key={row?.id}
              style={{
                color:
                  row?.totalItemQuantity < row?.inventoryAlarmValue
                    ? 'red'
                    : 'initial',
              }}
            >
              <td>{row?.description || 'N/A'}</td>
              <td>{row?.inventoryAlarmValue ?? 'N/A'}</td>
              <td>{row?.totalItemQuantity ?? 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
