import { swalAlertPopProps } from "../../utils/commonUtils";

export const removeItem = (
  operation,
  item,
  id,
  fireDb,
  userDetail,
  tableName,
  setBill,
  setCurrentDataArr,
  bill
) => {
  const parentNodeRef = fireDb?.child(userDetail?.mobileNumber);

  parentNodeRef
    ?.child("TableOrder")
    ?.once("value")
    ?.then((snapshot) => {
      let tableOrderData = snapshot.val();
      tableOrderData = tableOrderData[tableName];
      const arr1 = Object.entries(tableOrderData);
      let sum = 0;
      arr1.forEach((item) => {
        sum += Number(item[1].price * item[1].suppliedQuantity);
      });
      setBill((prev)=>{
        let prevData={...prev}
        // prevData.amount=sum
        return prevData
      })
      // setBill({ ...bill, amount: sum });
      setCurrentDataArr(arr1);

      if (tableOrderData) {
        Object.entries(tableOrderData).forEach(([fcmRowId, objectData]) => {
          if (fcmRowId === id) {
            if (operation === "+") {
              objectData.suppliedQuantity = objectData.suppliedQuantity + 1;
              objectData.printQty = objectData.printQty + 1;
            } else {
              objectData.suppliedQuantity = objectData.suppliedQuantity - 1;
              objectData.printQty = objectData.printQty - 1;
            }

            parentNodeRef
              ?.child(`TableOrder/${tableName}/${fcmRowId}`)
              ?.remove()
              ?.then(() => {})
              ?.catch((error) => {
                console.error("Error remove value:", error);
              });
          }
        });
      }
    })
    .catch((error) => {
      console.error("Error retrieving tableOrder data:", error);
    });
};

export const qtySet = (
  operation,
  value,
  id,
  fireDb,
  userDetail,
  tableName,
  setCurrentDataArr
) => {
  const parentNodeRef = fireDb?.child(userDetail?.mobileNumber);

  parentNodeRef
    ?.child("TableOrder")
    ?.once("value")
    ?.then((snapshot) => {
      let tableOrderData = snapshot.val();
      tableOrderData = tableOrderData[tableName];
      const arr1 = Object.entries(tableOrderData);
      let sum = 0;
      arr1.forEach((item) => {
        sum += Number(item[1].price * item[1].suppliedQuantity);
      });

      setCurrentDataArr(arr1);

      if (tableOrderData) {
        Object.entries(tableOrderData).forEach(([fcmRowId, objectData]) => {
          if (fcmRowId === id) {
            if (value) {
              objectData.suppliedQuantity = value;
              objectData.printQty = value;
            } else {
              if (operation === "+") {
                objectData.suppliedQuantity = objectData.suppliedQuantity + 1;
                objectData.printQty = objectData.printQty + 1;
              } else {
                if (objectData.suppliedQuantity > 1) {
                  objectData.suppliedQuantity = objectData.suppliedQuantity - 1;
                }
                if (objectData.printQty > 1) {
                  objectData.printQty = objectData.printQty - 1;
                }
              }
            }

            parentNodeRef
              ?.child(`TableOrder/${tableName}/${fcmRowId}`)
              ?.set(objectData)
              ?.then(() => {})
              ?.catch((error) => {
                console.error("Error updating value:", error);
              });
          }
        });
      }
    })
    .catch((error) => {
      console.error("Error retrieving tableOrder data:", error);
    });
};

export const removetableOrder = (
  fireDb,
  userDetail,
  tableName,
  navigate,
  routePath
) => {
  const parentNodeRef = fireDb?.child(userDetail?.mobileNumber);

  parentNodeRef
    ?.child("TableOrder")
    ?.once("value")
    ?.then((snapshot) => {
      let tableOrderData = snapshot.val();
      tableOrderData = tableOrderData[tableName];

      if (tableOrderData) {
        parentNodeRef
          ?.child(`TableOrder/${tableName}`)
          ?.remove()
          ?.then(() => {
            navigate(routePath.COUNTERSELL);
          })
          .catch((error) => {
            console.error("Error remove value:", error);
          });
        parentNodeRef
          ?.child(`bookedTables/${tableName}`)
          ?.remove()
          ?.then(() => {
            navigate(routePath.COUNTERSELL);
          })
          .catch((error) => {
            console.error("Error remove value:", error);
          });
      }
    })
    .catch((error) => {
      console.error("Error retrieving tableOrder data:", error);
    });
};

export const displayKotList = (
  fireDb,
  userDetail,
  tableName,
  setKotItem,
  setBill,
  bill,
  // setCurrentDataArr,
  setOpen2,
  Swal
) => {
  try {
    fireDb?.child(userDetail?.mobileNumber);
  } catch (err) {
    return;
  }
  const parentNodeRef = fireDb?.child(userDetail?.mobileNumber);
  parentNodeRef
    ?.child("TableOrder")
    ?.once("value")
    ?.then((snapshot) => {
      let tableOrderData = snapshot?.val();
      tableOrderData = tableOrderData[tableName];
      const arr1 = Object.entries(tableOrderData);
      let kotArr = [];
      arr1.forEach((item, index) => {
        if (item[1].printQty) {
          kotArr.push(item[1]);
        }
      });

      setKotItem(kotArr);
      if (kotArr.length > 0) {
        setOpen2(true);
      } else {
        // Swal.fire({
        //   text: "Please Select New Product ",
        //   icon: "warning",

        //   ...swalAlertPopProps,
        // });
        setOpen2(false);
      }
      let sum = 0;
      arr1.forEach((item) => {
        sum += Number(item[1].price * item[1].suppliedQuantity);
      });
      setBill((prev)=>{
        let prevData={...prev}
        // prevData.amount=sum
        // prevData.paybill=sum
        return prevData
      })
      // setBill({ ...bill, amount: sum, paybill: sum });
      // setCurrentDataArr(arr1);
    })
    .catch((error) => {
      console.error("Error retrieving tableOrder data:", error);
    });
};

export const tableOrderInFirebase = (
  tableObject,
  fireDb,
  userDetail,
  tableName,
  setBill,
  bill,
  setCurrentDataArr
) => {
  console.log("tableOrderInFirebase ", tableObject);

  console.log("tableOrderInFirebase 2", tableObject);

  const t222Ref = fireDb?.child(userDetail?.mobileNumber);
  const key = fireDb?.child("TableOrder").push().key;
  const currentDataFB = t222Ref.transaction(
    (currentData) => {
      if (currentData === null || currentData.TableOrder === null) {
        if (currentData === null) {
          currentData = {};
        }
        tableObject.fcmRowId = key;
        tableObject.suppliedQuantity = 1;
        tableObject.printQty = 1;
        tableObject.price =
          tableObject.price + tableObject.price * (tableObject.gst / 100);

        currentData.TableOrder = {};
        currentData.TableOrder[tableName] = { [key]: tableObject };

        if (!currentData.hasOwnProperty("bookedTables")) {
          currentData.bookedTables = {};
        }
        currentData.bookedTables[tableName] = {
          active: false,
          available: false,
          id: 0,
          name: tableName,
          discount:(bill?.discount)?bill.discount:0
        };
      } else {
        const TableOrder = currentData.TableOrder;

        if (!TableOrder.hasOwnProperty(key)) {
          if (!currentData.TableOrder.hasOwnProperty(tableName)) {
            currentData.TableOrder[tableName] = {};
          }

          tableObject.fcmRowId = key;
          tableObject.suppliedQuantity = 1;
          tableObject.printQty = 1;

          currentData.TableOrder[tableName][key] = tableObject;
          if (!currentData.hasOwnProperty("bookedTables")) {
            currentData.bookedTables = {};
          }
          currentData.bookedTables[tableName] = {
            active: false,
            available: false,
            id: 0,
            name: tableName,

            discount:(bill?.discount)?bill.discount:0
            
          };
        }
      }
      fireDb?.child("TableOrder").transaction((data) => {});
      const arr1 = Object.entries(currentData.TableOrder[tableName]);
      
      
      setCurrentDataArr(arr1);

      return currentData;
    },
    (error, committed, snapshot) => {
      if (error) {
        console.error("Transaction failed abnormally:", error);
      } else if (!committed) {
      } else {
      }
    }
  );
};

export const setKOT = (
  fireDb,
  userDetail,
  tableName,
  setBill,
  bill,
  setCurrentDataArr
) => {
  const parentNodeRef = fireDb?.child(userDetail?.mobileNumber);
  parentNodeRef
    ?.child("TableOrder")
    ?.once("value")
    ?.then((snapshot) => {
      let tableOrderData = snapshot.val();
      tableOrderData = tableOrderData[tableName];
      const arr1 = Object.entries(tableOrderData);

  
      setCurrentDataArr(arr1);

      if (tableOrderData) {
        Object.entries(tableOrderData).forEach(([fcmRowId, objectData]) => {
          objectData.printQty = 0;
          parentNodeRef
            ?.child(`TableOrder/${tableName}/${fcmRowId}`)
            ?.set(objectData)
            ?.then(() => {})
            .catch((error) => {
              console.error("Error updating value:", error);
            });
        });
      }
    })
    .catch((error) => {
      console.error("Error retrieving tableOrder data:", error);
    });
};

export const removeFromBookedNode = (
  fireDb,
  userDetail,
  tableName,
  navigate,
  routePath
) => {
  console.log("bookedTables remove");
  const parentNodeRef = fireDb?.child(userDetail?.mobileNumber);

  parentNodeRef
    ?.child("bookedTables")
    ?.once("value")
    ?.then((snapshot) => {
      let tableOrderData = snapshot.val();
      tableOrderData = tableOrderData[tableName];
      if (tableOrderData) {
        parentNodeRef
          ?.child(`bookedTables/${tableName}`)
          ?.remove()
          ?.then(() => {
            navigate(routePath.COUNTERSELL);
          })
          .catch((error) => {
            console.error("Error remove value:", error);
          });
      }
    })
    .catch((error) => {
      console.error("Error retrieving tableOrder data:", error);
    });
};
