import React, { useRef, useState } from "react";
import {
  Box,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  Modal,
  Pagination,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EventIcon from "@mui/icons-material/Event";
import SearchIcon from "@mui/icons-material/Search";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import AddIcon from "@mui/icons-material/Add";

import AddReceipt from "./addReceipt";
import { getAllaAdvance, getPaymentReceipt, getPaymentXlsx, getUnpaidUser } from "./paymentReceiptApi";
import ReceiptCart from "./receiptCard";
import { useEffect } from "react";
import axios from "../../utils/axios";
import {
  displayDateFormat,
  downloadXLSXFile,
  getEndOfMonth,
  getFirstDateOfCurrentMonthPayloadFormate,
  getFirstDateOfMonth,
  getLastDateOfCurrentMonthPayloadFormat,
  payloadDateFormat,
} from "../../utils/commonUtils";
import { api } from "../../utils/api";
import { getPaymentRec } from "./paymentReceiptCustomApiHandler";
import { searchedElementDisplay } from "./receiptFunctions";
import UserCard from "./userCard";
import PaymentReceiptSearchModal from "./paymentReceiptSearchModal";
import { ToFromDate } from "../../common/toFromDate";
import DisplayAdvance from "./displayAdvance/displayAdvance";
import CounterSalesItemCard from "../reports/totalCounterSaleReport.jsx/counterItemCard";
import Card, { CardD } from "./card";
const AdvancePayment = () => {
  const [clickedData,setClickedData]=React.useState(null)

  const [SelectedEndDate, setSelectedEndDate] = useState(null);

  const [billFilter, setBillFilter] = useState(null);
  const [displayEndDate, setDisplayEndDate] = useState(getEndOfMonth());

  const [SelectedStartDate, setSelectedStartDate] = useState(getFirstDateOfMonth());
  const [endDate, setEndDate] = useState(getLastDateOfCurrentMonthPayloadFormat());

  const [startDateFormat, setstartDateFormat] = useState(getFirstDateOfCurrentMonthPayloadFormate());
  const [employeeDetail, setEmployeeDetail] = useState(null)

  const datePickerRef2 = useRef();

   
  const [openT, setOpenT] = useState(false)
  const [open, setOpen] = useState(false);
  const [searchModal, setSearchModal] = useState(false)
  const [totals, setTotals] = useState();
  const [upPaidBillData, setUnPaidBillData] = useState();
  const [getPaymentObj, setGetPaymentObj] = useState({
    page: 1,
    pageSize: 12,
    // filter: "paymentmode",
    filter: "",

    search: billFilter,
    date: null,
  });
  const [unPaidBill, setUnPaidBill] = useState([]);
  const data = {
    customerMobile: "",
    amount: 0,
    name: "",
    transactionDetails:"",paymentMethod:"Online",
    reason:"",
    
  };
  const [billData, setBillData] = useState(data);

  const [searchReceiptsModal, setSearchReceiptsModal] = useState(false)
  const getUnpaid = async (param) => {
    await getUnpaidUser(param)
      .then((res) => {
        console.log("unPaid user", res?.data?.data[0]);

        setUnPaidBillData(res?.data?.data[0]);
      })
      .catch((err) => { });
  };
  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    records: 0,
    pageSize: 12,
  });
  const [numberOfRecords, setNumberOfRecords] = useState(1);
  const handleChange = (event, value) => {
    getPaymentRec(
      {
        fromDate: startDateFormat,
        toDate: endDate,
        ...getPaymentObj,
        page: value,
        pageSize: pagination.pageSize,
        search: billFilter,
      },
      getPaymentReceipt,
      setNumberOfRecords,
      setTotals,
      totals,
      setReceipts
    );
    setPagination({
      ...pagination,
      page: value,
    });
  };
 
  const [recepts, setReceipts] = useState([]);

  const [date, setDate] = useState();

  const [startDate, setStartDate] = useState(new Date());
  const [search, setSearch] = useState("");
  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };


  const [EmployeeDataAll, setEmployeeDataAll] = useState(null)

  useEffect(() => {
    if (getPaymentObj?.date) {

      setSearch("")
      // getPaymentRec(
      //   {
      //     ...getPaymentObj,
      //     page: pagination.page,
      //     fromDate: startDateFormat,
      // toDate: endDate,
      //     //   pageSize: pagination.pageSize,
      //     filter: billFilter != "" && billFilter !== null ? "paymentmode" : "",
      //     pageSize: 12,
      //     search: billFilter,
      //   },
      //   getPaymentReceipt,
      //   setNumberOfRecords,
      //   setTotals,
      //   totals,
      //   setReceipts
      // );
      getAllaAdvance({
        // ...getPaymentObj,
        page: pagination.page,
        fromDate: startDateFormat,
        toDate: endDate,
        //   pageSize: pagination.pageSize,
        filter: "",
        pageSize: 12,
        search: billFilter,
      }, (data) => {
        console.log("getAllaAdvance ", data)
        setEmployeeDataAll(data)
      })
    }
  }, [getPaymentObj]);

  useEffect(() => {
    // downloadXLSXFile();
    console.log("datE change", date);
    getAllaAdvance({
      // ...getPaymentObj,
      page: pagination.page,
      // fromDate: startDateFormat,
      // toDate: endDate,
      //   pageSize: pagination.pageSize, 
      filter: "",
      pageSize: 12,
      search: "",
    }, (data) => {
      console.log(" getAllaAdvance ", data)
      setEmployeeDataAll(data)

    })
    // getPaymentRec(
    //   {
    //     // ...getPaymentObj,
    //     // page: pagination.page,
    //     // pageSize: pagination.pageSize,
    //       fromDate: startDateFormat,
    //   toDate: endDate,
    //     "page": 1,
    //     "pageSize": 12,
    //     "filter": null,//"filter": null,
    //     "search": "",
    //     "date": null

    //   },
    //   getPaymentReceipt,
    //   setNumberOfRecords,
    //   setTotals,
    //   totals,
    //   setReceipts
    // );
  }, [startDateFormat, endDate]);

  return (
    <>
      <Grid
        container
        sx={{
          p: "20px",
        }}
      >






        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            pt: {
              xs: "10px",
              sm: "0px",
            },
            padding: { sm: "0px 16px" },

            // fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
          }}

        >
          <Box
            // disabled
            // size="small"
            readonly
            fullWidth
            onClick={() => {
              setSearchReceiptsModal(true)

            }}

            // id="name"
            // label="Search by Name/Mobile Number"
            // value={search}
            sx={{
              display: "flex",
              height: "23px",
              justifyContent: "space-between",
              width: "70%",
              border: "1px solid #c9c9c9",
              p: 1,
              borderRadius: "4px ",
              color: "grey",
              '&:hover': {
                cursor: 'pointer !important',
              },
            }}
            onChange={(e) => {

              // setSearch(e.target.value);
            }}

          >
            <Typography variant="p">
              {search !== "" ? search : "Search by Name/Mobile Number"}
            </Typography>
            <SearchIcon
              onClick={() => {
                // setSearchModal(true)
                setSearchReceiptsModal(true)

              }}
              sx={{
                color: (theme) => theme.palette.primary.main,
                cursor: "pointer",
              }}
            />
          </Box>

        

        </Grid>
       
        <Grid
          item
          xs={6}
          sx={{
            padding: "8px 16px",
            fontWeight: "500",
          }}
        >
         
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            padding: "8px 16px",
            fontWeight: "500",
            display: 'flex',
            justifyContent: "space-between",
            alignItems: 'center'
          }}
        >
           <Box>
          
          </Box>
          <Fab
            onClick={() => {
              // setOpen(true);
              setOpen(true);
            }}
            sx={{
              textTransform: "capitalize",
              width: {
                xs: "100%",
                sm: "auto",
              },
              // p: "10px",
              whiteSpace: "nowrap",
            }}
            size="small"
            color="primary"
            variant="extended"
            aria-label="add"
          >
            Add Receipt
            <AddIcon />
          </Fab>
        </Grid>
        {/* <Divider /> */}

        <Divider
          sx={{
            width: "100%",
            background: (theme) => theme.palette.primary.main,
          }}
        />
        {/* <ReceiptCart /> */}
        {/* <AddCircleIcon
          color="primary"
          onClick={async () => {
            setOpen(true);
          }}
          sx={{
            fontSize: "60px",
            cursor: "pointer",
            position: "fixed",
            bottom: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        /> */}
        <Grid container>
          {EmployeeDataAll?.data?.map((item, i) => {
            // if (
            //   !item?.customer?.toLowerCase().includes(search) &&
            //   !item?.customerMobile
            //     ?.toLowerCase()
            //     .includes(search.toLowerCase()) &&
            //   search.length > 0
            // ) {
            //   return null;
            // }
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                key={"payamentRecpt" + i}
                sx={{ p: "3px", borderRight: "3px solid #dfdfdf" }}


                onClick={()=>{
                  setClickedData(item)
                  setOpenT(true)
                }}
                
              >
                  <CardD
                   item={item} />

                {/* <ReceiptCart item={item} /> */}
              </Grid>
            );
          })}
        </Grid>
        <AddReceipt
          open={open}
          setOpen={setOpen}
          getRecept={() => {
            console.log("Payment saved ss");
            getPaymentRec(
              {
                ...getPaymentObj,
                page: pagination.page,
                pageSize: pagination.pageSize,
                fromDate: startDateFormat,
                toDate: endDate,
                search: billFilter,
              },
              getPaymentReceipt,
              setNumberOfRecords,
              setTotals,
              totals,
              setReceipts
            );
          }}

          callback={() => {

            getAllaAdvance({
              // ...getPaymentObj,
              page: pagination.page,
              fromDate: startDateFormat,
              toDate: endDate,
              //   pageSize: pagination.pageSize,
              filter: "",
              pageSize: 12,
              search: billFilter,
            }, (data) => {
              console.log("getAllaAdvance ", data)
              setEmployeeDataAll(data)
            })
          }}
          unPaidBill={unPaidBill}
          setUnPaidBill={setUnPaidBill}
          upPaidBillData={upPaidBillData}
          setUnPaidBillData={setUnPaidBillData}
          billData={billData}
          setBillData={setBillData}
          searchModal={searchModal}
          setSearchModal={setSearchModal}
        />

        <PaymentReceiptSearchModal
          searchModal={searchReceiptsModal}
          setSearchModal={setSearchReceiptsModal}
          unPaidBill={unPaidBill}
          getUnpaid={getUnpaid}
          setBillData={setBillData}
          search={search}
          setSearch={setSearch}

          setUnPaidBill={setUnPaidBill}
          setUnPaidBillData={setUnPaidBillData}
          setPagination={setPagination}
          billData={billData}
          callSearchApi={(searchName) => {
            setBillFilter("")
            getPaymentRec(
              {
                // ...getPaymentObj,
                // page: pagination.page,
                // pageSize: pagination.pageSize,

                "page": 1,
                "pageSize": 10000,
                fromDate: startDateFormat,
                toDate: endDate,
                "filter": "buyername",//"filter": null,
                "search": searchName,
                "date": getPaymentObj?.date

              },
              getPaymentReceipt,
              setNumberOfRecords,
              setTotals,
              totals,
              setReceipts
            );
          }}
        //  userDetail={userDetail}
        />
        {/* ------------------------------------------------------ */}


        <Grid container sx={{ pt: "10px" }}>
          <Grid item xs={4}>

          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              // bgcolor: "red",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <Stack direction="row" justifyContent="space-around" sx={{ mt: 2 }}> */}
            <Pagination
              count={
                Number.isInteger(numberOfRecords / pagination.pageSize)
                  ? numberOfRecords / pagination.pageSize
                  : parseInt(numberOfRecords / pagination.pageSize) + 1
              }
              page={pagination.page}
              onChange={handleChange}
            />
            {/* </Stack> */}
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        {/* ------------------------------- */}



              <DisplayAdvance open={openT}   setOpen={setOpenT}
              data={clickedData}

              setOpenAn={setOpen}
               />
      </Grid >
      {/* </Box> */}
      {/* </Drawer> */}
    </>
  );
};

export default AdvancePayment;
