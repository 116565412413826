import DetailPage, { Services } from "../common/detail";
import { ServicesContainer } from "../common/serviceContainer";
import { routePath } from "../constants/routePath";
import ItemCategories from "../features/ItemsCategories/itemCategories";
 
import OTP from "../features/Otp/otp";
import OTPAppoint from "../features/OtpAppoinment/OtpAppoinment";

import SetMpin from "../features/SetMpin/setMpin";
import AdvancePayment from "../features/advancePayment/advancePayment";
import { Advertice } from "../features/advertisement/advertisement";
 
import Appoinment from "../features/appoinment/appoinment";
import AppoinmentDashboard from "../features/appoinmentDashboard/appoinmentDashboard";
import AssociatedUsers from "../features/associatedUsers/associatedUsers";
import BulkMessage from "../features/bulkMessages/bulkMessages";
import { CustomerInformation } from "../features/counterInformation/counterInformation";
import CounterSell from "../features/counterSell/counterSell";
import CounterSellBilling from "../features/counterSellBilling/counterSellBilling";
import Dashboard from "../features/dashboard/dashboard";
import EmployeeInfo from "../features/employeeInfo/employeeInfoCM";
import EmployeePayRoll from "../features/employeePayRoll/employeePayRol";
import Salary from "../features/employeePayRoll/salary";
import Expense from "../features/expense/expense";
import FavRate from "../features/fav/favRate";
import HelpAndSupport from "../features/helpAndSupport/helpAndSupport";
import { Inventory } from "../features/inventary/inventory";
import { ItemService } from "../features/itemSerivce/itemService";
import ItemServices from "../features/items_services/ItemServices";
import KycProcess from "../features/kycProcess/kycProcess";
import CounterToken from "../features/landingPage/counterToken";
import Franchise from "../features/landingPage/franchise";
import LandingPage from "../features/landingPage/landingPage";
import MonthlyBill from "../features/landingPage/monthlyBill";
import { RestaurantPage } from "../features/landingPage/restaurantPage";
import Login from "../features/login/login";
import MobileMenuPage from "../features/mobileMenu/mobileMenuPage";
import MyBusiness from "../features/myBusiness/myBussiness";
import MyProfile from "../features/myProfile/myProfile";
import { AddOffers } from "../features/offers/addOffers";
import { GetAllOfferByBiller } from "../features/offers/getAllOfferByBiller";
import OTPForget from "../features/otpForget/otpForgetpin";
import OutstandingReport from "../features/outStandingReport/outStandingReport";
import PaymentStruct from "../features/payment/payment";
import PaymentReceipt from "../features/paymentReceipt/paymentReceipt";
import { PrivacyPolicy } from "../features/privay_term/privacyPolicy";
import { TermService } from "../features/privay_term/termService";
import UserProfile from "../features/profile/profile";
import Purchase from "../features/purchase/purchase";
import UserRegistration from "../features/registration/registration";
import CashCounterSale from "../features/reports/cashCounterSale/cashCounterSale";
import EmployeePaymentRole from "../features/reports/employeePaymentReport/employeePaymentRole";
import RateChangeItemHistory from "../features/reports/rateChangeHistory/rateChangeHistory";
import Reports from "../features/reports/reports";
import ResetMpin from "../features/resetPin/resetPin"; 
import { ReviewAndRating } from "../features/reviewRating/reviewAndRating";

import ServicesList from "../features/services/servicesList";
import Setting from "../features/setting/setting";
import ShareMessage from "../features/shareMessage/shareMessage";
import SmbAccountReacharge from "../features/smbAccoundReacharge/smbAccoundReacharge";
import Stocks from "../features/stocks/stocks";
import TableMaster from "../features/tableMaster/tableMaster";
import TableSection from "../features/tableSection/tableSection";
import UpdateBills from "../features/updateBills/updateBills";
import ExcelUpload from "../features/upload/uploadbill";
import UserLogin from "../features/userLogin/userLogin";
const menuLoginHeader = [
  {
    title: "Home",
  },
  {
    title: "About",
  },
];
export const RoutesSet = [
  {
    routePath: routePath.LANDINGPAGE,
    component: <LandingPage />,
  },
  {
    routePath: routePath.LANDINGPAGERESTORENT,
    component: <RestaurantPage />,
  },
  {
    routePath: routePath.OTPFORGET,
    component: <OTPForget />,
  },
  {
    routePath: routePath.LANDINGPAGECOUNTERTOKEN,
    component: <CounterToken />,
  },
  {
    routePath: routePath.DETAIL,
    component: <DetailPage />
  },
  {
    routePath: routePath.ADVERTICE,
    component: <Advertice />
  },
  {
    routePath: routePath.FAV,
    component: <FavRate />,
  },
  {
     routePath: routePath.REGISTRATION,
     component: <UserRegistration />,
   },
   
   {
    routePath: routePath.OTP,
    component: <OTP />,
  },
  
  {
    routePath: routePath.OTPAPPOINMENT,
    component: <OTPAppoint />,
  },
  
  {
    routePath: routePath.APPOINMENTDASHBOARD,
    component: <AppoinmentDashboard />,
  },
  {
    routePath: routePath.APPOINMENT,
    component: <Appoinment />,
  },
  {
    routePath: routePath.USERPROFILE,
    component: <UserProfile />,
  },
  {
    routePath: routePath.INVENTORY,
    component: <Inventory />,
  },
  {
    routePath: routePath.SETMPIN,
    component: <SetMpin />,
  },
  
  {
    routePath: routePath.LANDINGPAGEMONTHLYBILL,
    component: <MonthlyBill />,
  },
  {
    routePath: routePath.SERVICESLIST,
    component: <ServicesList />,
  },
  {
    routePath: routePath.LANDINGPAGEFRANCHISE,
    component: <Franchise />,
  },
  {
    routePath:routePath.CUSTOMERINFORMATION,
    component:<CustomerInformation />
  },
  {
    routePath:routePath.REVIEWANDRATING,
    component:<ReviewAndRating />
  },
  {
    routePath: routePath.LOGIN,
    component: <UserLogin />,
  },
  {
    routePath: routePath.DASHBOARD,
    component: <Dashboard />,
  },
  {
    routePath: routePath.COUNTERSELL,
    component: <CounterSell />,
  },
  {
    routePath: routePath.COUNTERSELLBILLING,
    component: <CounterSellBilling />,
  },
  {
    routePath: routePath.UPDATEBILLS,
    component: <UpdateBills />,
  },
  {
    routePath: routePath.MYPROFILE,
    component: <MyProfile />,
  },
  
  {
    routePath: routePath.EMPLOYEEINFO,
    component: <EmployeeInfo />,
  },
  {
    routePath: routePath.MYBUSSINESS,
    component: <MyBusiness />,
  },
  {
    routePath: routePath.TABLEMASTER,
    component: <TableMaster />,
  },
  {
    routePath: routePath.SHARESESSAGE,
    component: <ShareMessage />,
  },
  
  {
    routePath: routePath.ITEMSERVICES,
    component: <ItemService />,
  },
  {
    routePath: routePath.ADVANCEPAYMENT,
    component: <AdvancePayment />,
  },
  {
    routePath: routePath.TABLESECTION,
    component: <TableSection />,
  },{
    
    routePath: routePath.EMPLOYEEPAYROLL,
    component: <EmployeePayRoll />,
  },
  {
    routePath: routePath.ITEMCATEGORIES,
    component: <ItemCategories />,
  },
  { routePath: routePath.ASSOCIATEDUSERS, component: <AssociatedUsers /> },
  {
    routePath: routePath.PAYMENTRECEIPT,
    component: <PaymentReceipt />,
  },
  {
    routePath: routePath.OUTSTANDINGREPORT,
    component: <OutstandingReport />,
  },
  {
    routePath: routePath.MOBILEPAGEMENU,
    component: <MobileMenuPage menuLoginHeader={menuLoginHeader} />,
  },
  {
    routePath: routePath.SMBACCOUNTREACHARGE,
    component: <SmbAccountReacharge />,
  },
  {
    routePath: routePath.REPORTS,
    component: <Reports />,
  },
  {
    routePath: routePath.STOCKS,
    component: <Stocks />,
  },
  {
    routePath: routePath.PURCHASE,
    component: <Purchase />,
  },
  {
    routePath: routePath.EXPENCE,
    component: <Expense />,
  },
  {
    routePath: routePath.HELPSUPPORT,
    component: <HelpAndSupport />,
  },
  {
    routePath: routePath.RESETMPIN,
    component: <ResetMpin />,
  },
  {
    routePath: routePath.SETTING,
    component: <Setting />,
  },
  {
    routePath: routePath.RATECHANGE,
    component: <RateChangeItemHistory />,
  },
  {
    routePath: routePath.CASHCOUNTERSALE,
    component: <CashCounterSale />,
  },
  {
    routePath: routePath.KYCPROCESS,
    component: <KycProcess />,
  },
  {
    routePath: routePath.PRIVACYPOLICY,
    component: <PrivacyPolicy />,
  },

  {
    routePath: routePath.SERVICES,
    component: <ServicesContainer />,
  },
  {
    routePath: routePath.TERMSOFSERVICE,
    component: <TermService />,
  },
  {
    routePath: routePath.ADDOFFER,
    component: <AddOffers />,
  },
  {
    routePath: routePath.GETOFFERBYBILLER,
    component: <GetAllOfferByBiller />,
  },
  {
    routePath:routePath.PAYMENTSTRUCT,
    component:<PaymentStruct />
  },
  {
    routePath:routePath.BULTMESSAGE,
    component:<BulkMessage />
  },
  
  {
    routePath:routePath.  BLUESERIVE,
    component:<ExcelUpload />
  },
{
  routePath:routePath. SALARY,
  component:<Salary />
  
}
];
