import { attributeOfBill, swalAlertPopProps } from "../../utils/commonUtils";
import { customerNameByMobile } from "./counterSellBillingApi";

export const addBill = async ({
  payloadAddBill,
  addBillCounterSell,
  Swal,
  dispatch,
  setBillNo,
  navigate,
  routePath,callBack
}) => {
  console.log("fucntion api", payloadAddBill);
  await addBillCounterSell(payloadAddBill)
    .then((res) => {
      if (res?.data?.succeeded) {
        Swal.fire({
          text: "Bill Saved Successfully",
          icon: "success",
          timer: 2000, // Specify the time in milliseconds (e.g., 2000ms = 2 seconds)
          showConfirmButton: false,
        });
        dispatch(setBillNo(res?.data?.data?.billNumber));
        // dispatchEvent()
        

        






        















        








        if(callBack)
        {
          callBack(res?.data?.data)
        }
      } else {
        Swal.fire({
          text: res?.data?.message,
          icon: "error",
          ...swalAlertPopProps,
        });
      }

      navigate(routePath.COUNTERSELL);
    })
    .catch((err) => {});
};
export const handleDataChange = (
  snapshot,
  setBill,
  bill,
  setCurrentDataArr
) => {
  const tableOrderData = [];
  snapshot.forEach((childSnapshot) => {
    const tableName = childSnapshot.key;
    const tableData = childSnapshot.val();

    tableOrderData.push({ tableName, ...tableData });
  });
  const arr1 = Object.entries(tableOrderData);

  // let sum = 0;
  // let priceWithoutGst = 0;
  // let sumOfGst = 0;
  // let sumOfsgst=0
  // let sumOfcgst=0
  // let sumOfigst=0
  

  // console.log("arrarr===  arr1",arr1)
  // arr1.forEach((item) => {
  //   sum += Number(item[1].price * item[1].suppliedQuantity);
  //   priceWithoutGst += Number(item[1].currentPrice * item[1].suppliedQuantity);
  //   sumOfGst += Number(item[1].gst?item[1].gst:0);
  //   sumOfsgst += Number((item[1].currentPrice*item[1].sgst/100) * item[1].suppliedQuantity) ;
  //   sumOfigst += Number(item[1].igst* item[1].suppliedQuantity) ;
  //   sumOfcgst += Number(item[1].cgst * item[1].suppliedQuantity);
  // });
  
    
  // console.log("billp sum :",sum);
  // console.log("billp priceWithoutGst :",priceWithoutGst);
  // console.log("billp sumOfGst :",sumOfGst);
  let attributeofbill=attributeOfBill({arr1,config:{
    eleType:"arr",elementAccessMethod:"" 
  }})
  setBill((prev)=>{
    let prevData={...prev}
    prevData.amount=attributeofbill.amount
    prevData.paybill=attributeofbill.paybill
    prevData.sumOfGst=attributeofbill.sumOfGst
    prevData.sgst =attributeofbill.sgst
    prevData.igst =attributeofbill.igst
    prevData.cgst =attributeofbill.cgst
    prevData.priceWithoutGst=attributeofbill.priceWithoutGst
    return prevData
  })
  // { ...bill, amount: sum, paybill: sum, sumOfGst:sumOfGst, priceWithoutGst:priceWithoutGst });
  setCurrentDataArr(arr1);
};

export const getItems = async (
  param,
  ItemGETApi,
  CollectionTableItems,
  setCollectionTableItems,
  setIndexTableItem,
  setNumberOfRecords
) => {
  await ItemGETApi(param)
    .then(async (res2) => {
      let itemId = 0;
      let arry = res2.data.data;

      let arr = [...CollectionTableItems];
      arr.push({
        itemId: itemId,
        tableItems: arry,
      });
      console.log("get items counter ", res2?.data?.totalRecords);
      const index2 = arr.findIndex((obj) => obj.itemId === 0);
      setIndexTableItem(index2);
      setCollectionTableItems(arr);
      setNumberOfRecords(res2?.data?.totalRecords);
    })
    .catch(() => {});
};

export const getcustomerNameByMobile = async (param) => {
  let res = null;
  // await customerNameByMobile(param)
  //   .then((res) => {
  //     console.log("customer name ", res?.data?.data);
  //     res = res?.data?.data;
  //   })
  //   .catch((err) => {});
  // return res;
  try {
    const response = await customerNameByMobile(param);
    console.log("calb customer name ", response?.data?.data);
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching customer name", error);
    return null;
  }

  // try {
  //   const response = await customerNameByMobile(param);
  //   console.log("customer name ", response?.data?.data);
  //   return response?.data?.data;
  // } catch (error) {
  //   console.error("Error fetching customer name", error);
  //   return null;
  // }
};
