import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  Modal,
  Grid,
  Stack,
  Pagination,OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";

import DatePicker from "react-datepicker";
import EventIcon from "@mui/icons-material/Event";

import Swal from "sweetalert2";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";


import { ToFromDate } from "../../common/toFromDate";
import { convertDateFormat, convertDateToCustomFormat, getFirstDateOfCurrentMonthPayloadFormate, getLastDateOfCurrentMonthPayloadFormat, swalAlertPopProps } from "../../utils/commonUtils";
import { validationsAdd, validationsSave } from "../expense/expenceValidationFunction";
import ExpenseModalCard from "../expense/expenseModalCard";
import { totalPriceExpense } from "../expense/expenseFunction";
import { addData, updateBill } from "../expense/expenseCustomApiHandlers";
import { addExpense, updateExpense } from "../expense/expanceApi";

import SearchIcon from "@mui/icons-material/Search";

import "react-datepicker/dist/react-datepicker.css";
import { getUnpaidUser } from "../paymentReceipt/paymentReceiptApi";
import { getCounterSales } from "./outStandingReportCustomApiHandler";
import { GetAssistancePayrollDetail, getCounterSalesStatement, titleAssistantPayRoll } from "./outstandingApi";
import PaymentReceiptSearchModal from "../paymentReceipt/paymentReceiptSearchModal";
import PayrolSearchModal from "./payrollSearchModal";
import EmployeePayrollModalCard from "./employeePayrollModalCard";
import { PaymentmodeModal } from "./paymentmodeModal";
import { ModalHeader } from "../../common/modalHeader";
export default function EmployeePayRollModal({
  open,
  setOpen,
  getexpence,
  filterByProp,
  getPurchase,
  idProp,
  expenseItemArrProps,
  updateModalData,
}) {

const [flagPop,setflagPop]=useState(false)
  const [pagination, setPagination] = useState({
    page: 1,
    total: 10,
    records: 0,
  });
  const [USerObject, setUSerObject] = useState(null)


  const [searchOutStandingsModal, setSearchOutStandingsModal] = useState(false)

  const [upPaidBillData, setUnPaidBillData] = useState();
  const [billFilter, setBillFilter] = useState(null);

  const [unPaidBill, setUnPaidBill] = useState([]);
  const data = {
    customerMobile: "",
    amount: 0,
    name: ""
  };



  const [payloadGetCounterSales, setPayloadGetCounterSales] = useState({
    page: 1,
    pageSize: pagination.pageSize,
    sortBy: "",
    order: 0,
    buyerGroupId: 0,
    filter: "",
    search: "",
  });
  const [outStandingBills, setOutStandingBills] = useState({
    records: [],
    totalOustandingAmount: "",
    totalRecords: "",
  });
  const getUnpaid = async (param) => {
    await getUnpaidUser(param)
      .then((res) => {
        console.log("unPaid user", res?.data?.data[0]);

        setUnPaidBillData(res?.data?.data[0]);
      })
      .catch((err) => { });
  };

  //   const [pagination, setPagination] = useState({ page: 1, total: 10 });
  const [search, setSearch] = useState("");


  const [startDateFormat, setstartDateFormat] = useState(getFirstDateOfCurrentMonthPayloadFormate());
  const [endDate, setEndDate] = useState(getLastDateOfCurrentMonthPayloadFormat());

  // const data = {
  //   customerMobile: "",
  // };
  const [billData, setBillData] = useState(data);

  const onClose = () => {
    setOpen(false);
  };
  const handleChange = (event, value) => {
    setPagination({
      ...pagination,
      page: value,
    });
  };

  const [searchItem, setSearchItem] = useState([]);

  let payload = {
    amount: 0,
    description: "",
    
  };
  const [ExpenseItems, setExpenseItems] = useState(payload);

  const stockPayload = {
    page: 1,
    pageSize: 10,
  };


  const [ExpenseItemssArr, setExpenseItemssArr] = useState([]);
  const [id, setId] = useState();

  useEffect(() => {
    if (open === true) {
      if (filterByProp) {
        setFilterBy(filterByProp);
        setExpenseItemssArr(expenseItemArrProps);
        setId(idProp);
      }
    } else {
      console.log("close");
      setExpenseItems(payload);
      setFilterBy(filters[0].value);
      setExpenseItemssArr([]);
      setId();

      // setExpenseItemssArr([]);
    }
  }, [open]);
  const addLeadingZero = (value) => {
    return value < 10 ? "0" + value : value;
  };
  const [date, setDate] = useState(
    (() => {
      var currentDate = new Date();
      let dateContainer = String(currentDate).split(" ");
      var year = dateContainer[2];
      var month = dateContainer[1];
      var day = dateContainer[3];
      var formattedDate =
        year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);

      return formattedDate;
    })()
  );


  useEffect(() => {
    if (updateModalData?.id) {
      setDate(convertDateToCustomFormat(updateModalData?.date?.split("T")[0]))
    }

  }, [updateModalData])

  useEffect(() => { }, [filterByProp]);
  const [startDate, setStartDate] = useState(new Date());
  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const [filters, setFilters] = useState([
    {
      name: "Raw Material",
      value: "Raw material",
    },
    { name: "Salary", value: "Salary" },
    {
      name: "Shop Maintenance",
      value: "Shop Maintenance",
    },
    { name: "Other", value: "Other" },
  ]);
  const [filterBy, setFilterBy] = useState("RowMaterial");
  // const addData = async () => {};

  const [opent,setOpent]=useState(false)
  const [counterSalesHeader, setCounterSalesHeader] = useState(null)
  
  useEffect(()=>{
    console.log("setCounterSalesHeader ",counterSalesHeader)
  },[counterSalesHeader])
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "52%",
          overflowY: "auto",
          height: "90vh",
          position: "absolute",
          left: "25%",
          top: "10%",
        }}
      >
        
        <ModalHeader title={"Employee Payroll Details"} 
        onClose={(data)=>{
          setOpen(data)
        }}
        />

        
        <Divider />
        <Grid container sx={{ position: "relative" }}>
          <Grid item xs={12} >

          </Grid>

          <Grid item xs={12} sm={6} sx={{ borderRight: "1px solid #dfdfdf" }}>
            <DialogContent sx={{ pr: 2 }}>



              <Box sx={{ mt: 2, cursor: "pointer" }}>
                <ToFromDate


                  startDateFormat={startDateFormat}
                  setstartDateFormat={setstartDateFormat}





                  endDate={endDate}
                  setEndDate={setEndDate}

                />
              </Box>

              <Box
                readonly
                fullWidth
                onClick={() => {
                  setSearchOutStandingsModal(true)

                }}

                sx={{
                  mt:'12px',
                  display: "flex",
                  height: "23px",
                  justifyContent: "space-between",
                  // width: "70%",
                  border: "1px solid #c9c9c9",
                  p: 1,
                  borderRadius: "4px ",
                  color: "grey",
                  '&:hover': {
                    cursor: 'pointer !important',
                  },
                }}
                onChange={(e) => {

                  // setSearch(e.target.value);
                }}

              >
                <Typography variant="p">
                  {search !== "" ? search : "Search by Name/Mobile Number"}
                </Typography>
                <SearchIcon
                  onClick={() => {
                    setSearchOutStandingsModal(true)
                  }}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  id="workingDays"
                  size="small"
                  label="Working Days"
                  InputProps={{
                    style: {
                    },
                  }}
                  value={ExpenseItems?.amount !== 0 ? ExpenseItems?.amount : ""}
                  onChange={(e) => {
                    let val = e.target.value;
                    val = val.replace(/\D/g, "");
                    setExpenseItems({
                      ...ExpenseItems,
                      amount: val > 0 ? Number(val) : "",
                    });
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>

                <Grid
                  container
                  sx={{
                    p: "10px",
                    lineHeight: "2.3rem",
                  }}
                >
                  <Grid item xs={8}>
                    <Typography component="span" fontWeight="bold">
                      Today's Total Counter Sale:
                    </Typography>
                  </Grid>   
                  <Grid item xs={4}>
                    <Box component="span" sx={{
                      color: "#0da3ca",
                      display: "flex",
                      fontWeight: "600"
                    }}>{counterSalesHeader?.totalSales}</Box> 
                  </Grid>
                  <Grid item xs={8}>
                    <Typography component="span" fontWeight="bold">
                      Raw Material
                    </Typography>
                  </Grid>   
                  <Grid item xs={4}>
                    <Box component="span" sx={{
                      color: "#0da3ca",
                      display: "flex",
                      fontWeight: "600"
                    }}>
                                            {counterSalesHeader?.rawMaterialValue}

                      </Box> 
                  </Grid>
                  <Grid item xs={8}>
                    <Typography component="span" fontWeight="bold">
                    Salary
                    </Typography>
                  </Grid>   
                  <Grid item xs={4}>
                    <Box component="span" sx={{
                      color: "#0da3ca",
                      display: "flex",
                      fontWeight: "600"
                    }}>
                      {counterSalesHeader?.salary}
                       
                      </Box> 
                  </Grid>
                  <Grid item xs={8}>
                    <Typography component="span" fontWeight="bold">
                      Incentive Amount on 40.0%
                    </Typography>
                  </Grid>   
                  <Grid item xs={4}>
                    <Box component="span" sx={{
                      color: "#0da3ca",
                      display: "flex",
                      fontWeight: "600"
                    }}>
                      {counterSalesHeader?.paymentPercentageAmount}
                      </Box> 
                  </Grid>
                  <Grid item xs={8}>
                    <Typography component="span" fontWeight="bold">
                      Deduction
                    </Typography>
                  </Grid>   
                  <Grid item xs={4}>
                    <Box component="span" sx={{
                      color: "#0da3ca",
                      display: "flex",
                      fontWeight: "600"
                    }}> {
                      counterSalesHeader?.deduction
                    } </Box> 
                  </Grid>
                  <Grid item xs={8}>
                    <Typography component="span" fontWeight="bold">
                      Advance Paid - Amount
                    </Typography>
                  </Grid>   
                  <Grid item xs={4}>
                    <Box component="span" sx={{
                      color: "#0da3ca",
                      display: "flex",
                      fontWeight: "600"
                    }}> 
                    
                    {counterSalesHeader?.deduction}
                    </Box> 
                  </Grid>
                  <Grid item xs={8}>
                    <Typography component="span" fontWeight="bold">
                      Net Payable Amount
                    </Typography>
                  </Grid>   
                  <Grid item xs={4}>
                    <Box component="span" sx={{
                      color: "#0da3ca",
                      display: "flex",
                      fontWeight: "600"
                    }}>{counterSalesHeader?.netPayableAmount}</Box> 
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
          </Grid>
          <Grid item sm={6} sx={{ justifyContent: "space-between", flexDirection: "row-reverse" }}>
            <Box
              sx={{
                height: "28vh",
                overflowY: "scroll",
              }}
            >
              {counterSalesHeader?.counterSalesHeader[0]?.counterSalesChild?.map((item, index) => {
                return (
                  <EmployeePayrollModalCard
                    date={convertDateToCustomFormat(counterSalesHeader?.counterSalesHeader[0]?.billDate)}
                    index={index}
                    paymentMode={counterSalesHeader?.counterSalesHeader[0]?.customer}
                    item={item}
                    ExpenseItemssArr={ExpenseItemssArr}
                    setExpenseItemssArr={setExpenseItemssArr}
                  />
                );
              })}
            </Box>
            <Box>

              <Divider
                sx={{
                  background: (theme) => theme.palette.primary.main,
                  width: "100%",
                  mt: 2,
                }}
              />
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-around'
              }}>
                <Typography color="primary">
                  <strong>Total Amount: &nbsp;
                  </strong>
                </Typography>
                <Box
                  component="span"
                  sx={{
                    // marginLeft: "8px",
                    color: (theme) => theme.palette.secondary.main,
                  }}
                >
                  {" "}
                  <CurrencyRupeeIcon sx={{ fontSize: "14px" }} />
                  {/* {counterSales?.totalAmount} */}
                  {/* {100} */}
                  {totalPriceExpense(ExpenseItemssArr)}
                </Box>
              </Box>

            </Box>
            <DialogActions>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  
                  if(!ExpenseItems?.paymentMethod){
                    setOpent(true)
                    return;
                  }
                  else{
                    
                    console.log("bill Data ", billData);
                    // const valid = validationsSave(ExpenseItemssArr);
                    // if (USerObject.employeeMasterId) {
                    // id
                    //   ? 
                    //   true<e=tex
                    //   : 
                    console.log("USerObject ",USerObject)
                    titleAssistantPayRoll({
                      amount: ExpenseItems.amount,
                      fromDate: startDateFormat,
                      toDate: endDate,
                      employeeMasterId: parseFloat(USerObject?.employeeId),
                      // amount:
                    }, () => {  
                      setOpent(false)
                      setOpen(false);
  
                    })
                  }
            
                  // } else {
                  //   Swal.fire({
                  //     text: valid.msg,
                  //     icon: "warning",
                  //     ...swalAlertPopProps,
                  //   });
                  // }
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
        <PayrolSearchModal
          searchModal={searchOutStandingsModal}
          setSearchModal={setSearchOutStandingsModal}
          unPaidBill={unPaidBill}
          getUnpaid={getUnpaid}
          setBillData={setBillData}
          search={search}
          setSearch={setSearch}
          setUSerObject={(data) => {
            console.log("data ", data)
            GetAssistancePayrollDetail({
              fromDate: startDateFormat,
              toDate: endDate,
              employeeMasterId: data?.id,
              "page": 1,
              "pageSize": 1000,
              "workingDays": 0
            }, (data) => {
              console.log("data == ", data)
              setCounterSalesHeader(data?.data)
            })
            setUSerObject(data)
          }}
          setUnPaidBill={setUnPaidBill}
          setUnPaidBillData={setUnPaidBillData}
          setPagination={setPagination}
          billData={billData}
          callSearchApi={(searchName) => {
            setBillFilter("")
            // payloadGetCounterSales.search
            setPayloadGetCounterSales({ ...payloadGetCounterSales, search: "" })
            // getCounterSales(
            //   {
            //     page: 1,
            //     pageSize: 10000,
            //     sortBy: "",
            //     order: 0,
            //     buyerGroupId: 0,
            //     filter: "buyername",
            //     search: searchName,
            //   },
            //   outStandingBills,
            //   getCounterSalesStatement,
            //   setNumberOfRecords,
            //   setOutStandingBills
            // );

          }}
        //  userDetail={userDetail}
        />


<PaymentmodeModal  
callback={(data)=>{
  setExpenseItems({...ExpenseItems,...data})
}}
        open={opent}
        setOpen={setOpent}/>
      </Box>

    </Modal>
  );
}
