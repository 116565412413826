import { DialogTitle, Divider } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close";



export const ModalHeader=({title,onClose})=>{
    return (
        <>
        <DialogTitle
        color="primary"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: 'auto',
          p: "0px 10px !important"
        }}
      >
        {title}   {" "}
        <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => onClose(false)} />
      </DialogTitle>
      <Divider />
      </>
       
    )
}