import { Box, Fab, Grid, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router";

import { useEffect, useState } from "react";

import { setUserDetail, setbusinessChooseTab } from "../app/store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router/dist";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PersonIcon from "@mui/icons-material/Person";
import StorefrontIcon from "@mui/icons-material/Storefront";
import TableBarIcon from "@mui/icons-material/TableBar";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import SearchIcon from "@mui/icons-material/Search";
import HandshakeIcon from "@mui/icons-material/Handshake";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { routePath } from "../constants/routePath";
import LogOutPop from "./logoutPop";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { capitalizeFirstLetter } from "../app/common/commonFunction";
export default function CustomerMangementbar() {
  const navigate = useNavigate();
  const headerMenu = useSelector((state) => state.reducer.headerMenu);
  const [header1, setHeader1] = useState([]);
  const [header2, setHeader2] = useState([]);

  // const arrN = (urlHeaderMenu) => {
  //   let arr1 = [...header1];

  //   let arr2 = [...header2];

  //   if (arr1.length == 0) {
  //     urlHeaderMenu.forEach((element, index) => {
  //       if (index % 2 == 0) {
  //         arr1.push(element);
  //       } else {
  //         arr2.push(element);
  //       }
  //       setHeader1(arr1);
  //       setHeader2(arr2);
  //     });
  //   }
  // };
  const businessChooseTab=useSelector((state)=>state.reducer.businessChooseTab)

  const iconsObj = {
    PersonIcon: PersonIcon,
    StorefrontIcon: StorefrontIcon,
    TableBarIcon: TableBarIcon,
    TableRestaurantIcon: TableRestaurantIcon,
    SearchIcon: SearchIcon,
    HandshakeIcon: HandshakeIcon,
    DashboardIcon: DashboardIcon,
    CurrencyRupeeOutlinedIcon: CurrencyRupeeOutlinedIcon,
    SettingsOutlinedIcon: SettingsOutlinedIcon,
    LockOutlinedIcon: LockOutlinedIcon,
    HeadsetMicOutlinedIcon: HeadsetMicOutlinedIcon,
    ShareOutlinedIcon: ShareOutlinedIcon,
    LogoutOutlinedIcon: PowerSettingsNewIcon,
  };
  const sideBarMenu = useSelector((state) => state.reducer.sideBarMenu);
  const customerChategory = useSelector((state) => state.reducer.customerChategory);

  const dispatch = useDispatch();

  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(-1);
  useEffect(() => {
    const indexRoute3 = customerChategory.findIndex(
      (it) => it.route === location.pathname
    );

    setCurrentPage(indexRoute3);
  }, [location.pathname]);
  

  return (
    <Grid
      container
      spacing={0}
      sx={{
        position: "sticky",
        top: "104px",
        background: "#3695b6",
        overflowY: "auto",
        // maxHeight: "calc(100vh - 60px)",
        maxHeight: {
          xs: "100vh",
          // sm: "calc(100vh - 60px)",
          // sm:
        },
        scrollbarWidth: "thin",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {},
        "&::-webkit-scrollbar-thumb": {
          background: (theme) => theme.palette.secondary.main, // Color of the thumb (scrollbar handle)
          borderRadius: "6px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#FFC107",
        },
      }}
    >
      <Grid sx={{ minHeight: "100vh", height: "auto" }} item md={12}>
        {customerChategory?.map((item, index) => {
          const indexRoute = customerChategory.findIndex(
            (it) => it.systemFuctionName === item.systemFuctionName
          );
          const IconComponent = iconsObj[customerChategory[indexRoute]?.icon];

          return (
            <>
            <Box
              key={"siderbar" + index}
              bgcolor="primary"
              sx={{
                padding: item.systemFuctionName === "Sign Out" ? "4px" : "10px",
                height:
                  item.systemFuctionName !== "Sign Out" ? "1.5rem" : "3rem",
                borderBottom: "1px solid white",
                background: currentPage === index ? "#127093" : "",
                display: "flex",
                alignItems: "center",
                justifyContent:'space-between',
                cursor: "pointer",
                 transition:'0.3s'
              }}
              onClick={async (e) => {
                navigate(item?.route); 
                if(item?.tab?.length>0){
                  // dispatch(setbusinessChooseTab(item?.tab[0]))
                  dispatch(setbusinessChooseTab({value:item?.tab[0].value,label:item?.tab[0].label}))
                }
              }}
            >
              {item.systemFuctionName === "Sign Out" && (
                <Box
                  sx={{
                    mr: "10px",
                    "& > svg": {
                      fill: "white",
                    },
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <Fab
                    // color="red"
                    sx={{
                      backgroundColor: "red !important",
                      width: "40px",
                      height: "40px",
                    }}
                    // sx={{ float: "right" , }}
                    onClick={() => {
                      // setOpen(true);
                    }}
                  >
                    {/* <LogoutIcon /> */}
                    <PowerSettingsNewIcon />
                  </Fab>
                </Box>
              )}
              {/* {item.systemFuctionName !== "Sign Out" && (
                <Box
                  sx={{
                    mr: 1,
                    "& > svg": {
                      fill: "white",
                    },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {IconComponent && <IconComponent />}
                </Box>
              )} */}
              <Typography variant="standard" color="white" sx={{fontWeight:currentPage === index ? 600 : 400}}>
                {" "}
                {/* {item.regionalFunctionName} */}
                {(function () {
                          const indexRoute = headerMenu.findIndex(
                            (it) =>
                              it.systemFuctionName === item.systemFuctionName
                          );
                          // if (NameData[item?.systemFuctionName]) {
                          //   return item?.systemFuctionName;
                          // }
                          return capitalizeFirstLetter(headerMenu[indexRoute]?.regionalFunctionName);
                        })()}
              </Typography>
              { item.tab && (currentPage === index ? <ArrowDropDownIcon sx={{color:'#fff'}}/> : <ArrowRightIcon sx={{color:'#fff'}}/>)}
            </Box>
            {currentPage === index && item.tab && (
                  <Box sx={{ display:'block',borderBottom: "1px solid white",}}>
                    {item?.tab?.map((ele, index) => {
                      return (
                        <Box 
                         bgcolor="primary"
                        onClick={()=>{
                          dispatch(setbusinessChooseTab({value:ele.value,label:ele.label}))
                        }}
                        
                           sx={{border:'1px solid white',display:'flex', alignItems:'center', justifyContent:'space-between', borderRadius: '10px',
                          cursor:'pointer',
                          padding: '7px 13px',
                          margin: '8px',
                        background: businessChooseTab?.value==ele.value?"#fff":"" , transition:'0.3s',
                        boxShadow: businessChooseTab?.value==ele.value?'1px 4px 3px #0000003d' :'unset'}}
                        
                        >
                        <Typography
                          variant="standard"
                          color={businessChooseTab?.value==ele.value?"#127093" :'#fff'}
                          textAlign={"left"}
                        >
                          {capitalizeFirstLetter(ele.label)}
                        </Typography>
                        {businessChooseTab?.value==ele.value &&( <KeyboardArrowRightIcon sx={{color:'#127093'}}/>)}
                        </Box>
                      );
                    })}
                  </Box>
                )}
            </>
          );
        })}
      </Grid>
      <LogOutPop open={open} setOpen={setOpen} />
    </Grid>
    // </Box>
  );
}
