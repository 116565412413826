export const ENCRYPTIONKEY = "9z8x7c1m2n3b5l4k";
export const LOGIN = "connect/token";
export const api = "user/api/v1/";
export const GETUSER = api + "UserMaster/GetUserDetails";
export const GETMENU = api + "LocalizationOfFunctions/GetBillerHamburgerMenu/";
export const GETMENUHEADER = api + "LocalizationOfFunctions/GetBillerMainMenu/";
export const GETTABLESECTION = api + "Restaurant/GetTableSectionList";
export const GETTABLE = api + "Restaurant/GetTableListV2";
export const ITEMCATEGORY = api + "ItemCategory/GetItemCategories";
export const ITEMGET = api + "ItemsMaster/GetItems";
export const USERPROFILE = api + "UserMaster/GetUserProfile";
export const GETUSERPROFILE=api+"UserMaster/GetUserProfile"
// export const UPDATEUSERPROFILE = api + "UserMaster/UpdateUserProfile";
export const UPDATEUSERPROFILE = api + "UserMaster/UpdateUserProfile";
export const USERPROFILEIMG = api + "UserMaster/GetUserProfile";
export const UPDATEUSERPROFILIMAGE = api + "UserMaster/ChangeProfilePicBase64";
export const GETBUSSINESSDATA = api + "MyBusiness/GetActiveBusiness";
export const ADDCOUNTERSALESCUTOMERDETAIL=api+ "Salon/AddCounterSalesCustomerDetails"
// export const "http://103.13.114.194:4080/webapp/user/api/v1/"
export const UPDATEBUSSINESSDATA = api + "MyBusiness/Update";
export const UPDATECUSTOMERDETAIL= api+"Salon/UpdateCounterSalesCustomerDetails"
export const ADDOFFER=api+"Salon/AddOfferMessage"
 
export const GETALLCUSTOMERDETAILFORSALON= api+"Salon/GetAllCustomerDetailsForSalon"
export const UPDATEOFFERMESSAGE=  api+"Salon/UpdateOfferMessage"
export const ADDBILL = api + "CounterSales/Add";


export const GETALLOFFER=api+"Salon/GetAllOffers"

export const GETBILL = api + "CounterSales/GetAllBills";
export const UPDATEBILL = api + "CounterSales/Update";

export const LASTSERVICEDETAILS=api+"Salon/GetCustomerLastServiceDetails"
export const UPDATECOUNTERSALESCUSTOMER=api+"Salon/UpdateCounterSalesCustomerDetails"
export const ADDTABLE = api + "Restaurant/AddTable";
export const GETDASHBOARDDATE = api + "ShowMyBills/GetBillerDashboardDetails";
export const GETMASTERTABLE = api + "Restaurant/GetTableList";

export const GETITEMS = api + "ItemsMaster/GetItems";
export const ADDITEMS = api + "ItemsMaster/Add";
export const UPDATEITEMS = api + "ItemsMaster/UpdateItem";
export const ADDCATEGORIES = api + "ItemCategory/Add";
export const UPDATECATEGORIES = api + "ItemCategory/Update";

export const GETTABLESECTIONS = api + "Restaurant/GetTableSectionList";
export const GETNOTASSIGNTABLELIST = api + "Restaurant/GetNotAsssignTableList";
export const UPDATETABLESECTION = api + "Restaurant/UpdateTableSection";
export const ADDTABLESECTION = api + "Restaurant/AddTableSection";
export const GETITEMCATEGORIES = api + "ItemCategory/GetItemCategories";
export const UPDATETABLEMASTER = api + "Restaurant/UpdateTable";
export const GETASSOCIATEDUSERS = api + "AssociatedUser/GetAssociatedUsers";
export const ADDASSOCIATEDUSER = api + "AssociatedUser/AddAssociateUser";
export const UPDATEASSOCIATEDUSER = api + "AssociatedUser/UpdateAssociateUser";
export const GETACCOCIATEDUSERSSELECTEDMENU =
  api + "AssociatedUser/GetAssociatedUsersSelectedMenu";
export const GETALLEXPENSESCATEGORY= api+"Salon/GetAllExpensesCategory"
   
export const SETASSOCIATEDUSER =
  api + "AssociatedUser/SetAssociatedUsersSelectedMenu";
export const GETUNPAIDBILLS = api + "CounterSales/GetUnpaidBillsByBuyer";

export const GETPAYMENTRECEIPT = api + "CounterSales/GetPaymentReceipts";
export const GETCOUNTERSALESCUSTOMERS =
  api + "CounterSales/GetCouterSalesCustomers";
  export const GETACTIVEEMPLOYEELIST= api+"Salon/GetActiveEmployeeList"
 export const TTLEASSISTNTPAYROLL= api+"CounterSales/SettleAssistantPayrollDetails"
 
export const GETASSISTANTPAYROLLDETAIL=  api+"CounterSales/GetAssistantPayrollDetails"
export const  GETALLADVANCEFOR= api+ "BuyersInfo/GetAllCustomerAdvanceForSalon"
export const ADDCUSTOMERADVANCE= api+"BuyersInfo/AddCustomerAdvance"
export const ADDCASHPAYMENT = api + "CounterSales/AddCashPayment";
export const ADDBILLCASHRECEIPT = api + "PaymentReceipt/AddBillerCashReceipt";
export const GETPAYMENTXLSX = api + "CounterSales/GetPaymentReceiptsXlsx";
export const GETCOUNTERSELAESSTATEMENT =
  api + "Reports/ReportOutstandingCouterSalesStatement";
  export const GETCOUNTERSALESpPAYROLL= api+"CounterSales/GetAssistantPayrollSearch"
export const GETACCOUNTRECHAGEDETAILS =
  api + "BuyersInfo/GetAccountRechargeDetails";
export const RAWMATERIALRPT=  api+"Reports/RawMaterialRPT"
export const GETRENEWSUBSCRIPTIONDETAIL =
  api + "BuyersInfo/GetRenewSubscriptionDetails";
export const GETCATEGORYWISECOUNTERSALE =
  api + "CounterSales/GetCategoryWiseCounterSale";
export const GETMONTLYGSTDETAILS = api + "CounterSales/GetMontlyGSTDetails";
export const GETDISCOUNTEDITEMS = api + "ItemsMaster/GetDiscontinuedItems";
export const ADVANCEREPORT=api+ "Reports/GetEmployeeAdvanceReport"
export const GETOVERALLCOUNTERDETAILS =
  api + "CounterSales/GetOverAllCounterSalesDetails";
export const GETASISTANTPAYROLLREPORT =api+"Reports/GetAssistantPayrollReport"
export const GETCOUNTERSALESREPORT = api + "CounterSales/GetCounterSalesReport";
export const GETSTOCKDETAILS = api + "Purchse/GetStockDetails";
export const GETPURCHASEDETAILS = api + "Purchse/GetDetails";
export const PURCHASEADD = api + "Purchse/Add";

export const GETDETAILSEXPENSE = api + "Expense/GetDetails";
export const ADDEXPENSE = api + "Expense/Add";
export const GETDETALS = api + "Expense/GetDetailsXlsx";
export const UPDATEEXPENSE = api + "Expense/Update";
export const GELLALLCATEGORIESHELPSUPPORT =
  api + "FAQCategory/GetAllCategories";
export const GETALLUSERFAQ = api + "FAQs/GetAllUserFAQsByLanguage";
export const ADDBILLERSERICE = api + "ServiceRequest/AddBillerServiceRequest";
// export const ADDB"ServiceRequest/AddBillerServiceRequest"
export const UPDATEPIN = api + "UserMaster/SetMPin";

export const PRINTERSETTING = api + "MyBusiness/UpdatePrinterSettings";

export const GETCUSTOMERNAMEBYMOBILE =
  api + "CounterSales/GetCustomerNameByMobile";

export const GETRATECHANGEHISTORY = api + "ItemsMaster/GetRateChangeHistory";
export const GETCOUNTERSALESSUMMARY =
  api + "CounterSales/GetCounterSalesSummary";

export const GETKYCDOCUMENTS = api + "KYCDocument/GetKYCDocuments";
export const UPLOADDOCUMENTS = api + "KYCDocument/UploadKYCDocumentBase64";




export const GETCUSTOMERADVANCE= api+"BuyersInfo/GetCustomerAdvance"
//new apis
export const GETBUSINESS=api+"MyBusiness/GetSearchBusiness";
export const GETBILLERITEM=api+ "ItemsMaster/GetBillerItems"
export const USERMASTER=api+"UserMaster/Add"
export const VALIDOTP=api+"UserMaster/ValidateUserOTP"

export const   USERMPIN=api+  "UserMaster/SetUserMPin"
export const ADDCUSTOMERAPPOINMENT=      api             +"Salon/AddCustomerAppointmentForBuyer"
export const ADDCUSTOMERAPPOINMENTANAMONUS=      api             +"Salon/AddCustomerAppointmentForAnonymousBuyer"
export const VALIDCUSTOMERAPPOINMENTANAMONUS=      api             +"Salon/ValidateCustomerAppointmentForAnonymousBuyer"


export const GETCUSTOMERAPPOINMENTS= api+"Salon/GetCustomerAppointments"
export const GETEMPLOYEE=api+"Salon/GetAllEmployeeList"
export const ADDEMPLOYEE=api+"Salon/AddEmployee"
export const UPDATEEMPLOYEE=api+"Salon/UpdateEmployee"
export const ADDCUSTOMERAPPOINMENTI=   api+"Salon/AddCustomerAppointment"
export const UPDATECUSTAPPOINMENT=      api+"Salon/UpdateCustomerAppointment"


export const GETTERMSERVICE=api+"ProductDocumentInformation/GetTermsofServiceContent"
export const GETPRIVACYPOLICY=api+"ProductDocumentInformation/GetPrivacyPolicyContent"


export const ADDOFFERS=api+"Salon/AddOffers"
export const GETALLOFFERS=api+"Salon/GetAllOffers"
export const UPDATEOFFERS=api+"Salon/UpdateOffers"
export const GETALLOFFERSBYBILLER=api+"Salon/GetAllOffersByBiller"

export const GETREVIEWANDRATING=api+"Salon/GetReviewAndRating"

export const ADDREVIEWANDRATING=api+"Salon/addReviewAndRating"

export const GETALLREVIEWANDRATING=api+"Salon/getAllReviewAndRating"

export const ADDEXPENSESCATEGORY= api+"Salon/AddExpensesCategory"
export const UDATEEXPENSESCATEGORY= api+ "Salon/UpdateExpensesCategory"

export const GETALLCUSTOMERADVACE=api+ "BuyersInfo/GetAllCustomerAdvance"

export const  GETALLSALARYINCENTIVE=api+ "Salon/GetAllSalaryIncentives"
export const  GETALLSALARYCOMPONENT= api+"Salon/GetAllSalaryComponent"
export const  ADDSALARYINCENTIVE=api+"Salon/AddSalaryIncentive"
export const  UPDATESALARYINCENTIVE=api+"Salon/UpdateSalaryIncentive"
export const  ADDSALARYCOMPONENT=api+"Salon/AddSalaryComponent"
export const UPDATESALARYCOMPONENT=api+"Salon/UpdateSalaryComponent"

export const GETALLDEDUCTIONCOMPONEN=api+"Salon/GetAllDeductionComponents"
export const ADDDEDUCTIONCOMONEN=api+"Salon/AddDeductionComponent"
export const UPDATEDEDUCTIONCOMPONENT=  api+"Salon/UpdateDeductionComponent"

export const BULKSERVICESIMPORT=api+"ItemsMaster/BulkServicesImport";
export const BULKCOUNTERSELLCUSTOMERIMPORT=api+"Salon/BulkCounterSalesCustomerImport";
export const BULKIMPORTMPORT=api+"ItemsMaster/BulkProductsImport"
export const GETITEMBYBARCODE=api+"ItemsMaster/GetItemByBarCode"
export const GETALLEDUCATIONALDETAILS=api+"Salon/getAllEducationalDetails"
export const ADDEDUCATIOIN=api+"Salon/AddEducationalDetail"
export const UPDAT__DUCATIOIN=api+"Salon/UpdateEducationalDetail"
export const ADDEXPERINCEDETAIL=api+"Salon/AddExperienceDetail"
export const UPDATEEXPERINCEDETAIL=api+"Salon/UpdateExperienceDetail"
export const GETALLEXPERINCEDETAIL= api+"Salon/GetAllExperienceDetails"
export const GETASSISTANTPAYROLLDETAILFOREMP=api + "CounterSales/GetAssistantPayrollSearchV2";


export const GETALLOTHERALLOWANCE=api+"Salon/GetAllOtherAllowances"
export const ADDOTHERALLOWANCE=api+"Salon/AddOtherAllowance"
export const UPDATEOTHERALLOWANCE=api+"Salon/UpdateOtherAllowance"
