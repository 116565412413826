// import { Box, Button, Typography, Modal, Grid } from "@mui/material";

import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  Container,
  Grid,
  Box,
  Stack,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Modal,
  DialogTitle,
  Tabs,
  Tab,
  Switch,
  FormGroup,
} from "@mui/material";

import {
  DeductionUp,
    adDeduction,
  addEmployee,
  getNewSubscriptionReachageDetails,
  updateEmployee,
} from "./appoinmentDashboardApi";
import { payloadDateFormat } from "../../utils/commonUtils";
import { getDataRechageDetail } from "./smbAccountRechargeCustomApiHandler";
import { getMonthDifference } from "./smbAccounrReachargeDateFunction";
import CloseIcon from "@mui/icons-material/Close";
import { getAlldetailSerice } from "../services/servicesListApi";

// import "./test.css";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { bookApoinment } from "./asppoinmentApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { routePath } from "../../constants/routePath";
import { setSession } from "../../utils/commonUtils";
import { setCustomerAppointmentId } from "../../app/store/reducer";
// import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { TextFieldSearch } from "../../common/fieldText";
import PoprchModal from "../payment/popSearch";
// import { getAlldetailSerice } from "../services/servicesListApi";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function FormModalT({ open, setOpen, modalData, getAll }) {
  const [pagination, setPagination] = useState({ page: 1, total: 10 });
  const [subscription, setSubscription] = useState();
  const [value, setValue] = React.useState("personalInfo");

  const handleChange_Tab = (event, newValue) => {
    // setPageNo(1);

    setValue(newValue);
  };

  const onClose = () => {
    setOpen(false);
  };

  const struct = { 
    employeeId: "", 
    courseName: "",
    durationInYear: "",
    passoutDate: "",
    courseDetails: "",
    percentage: "",
    grade: "",
    university: "",
    institute: ""
      
  };
  const appnType = useSelector((state) => state.reducer.apppoinmentUerType);
  const dispatch = useDispatch();
  const info = useSelector((state) => state.reducer.detail);
  const [formData, setFormData] = useState({ ...struct });

  useEffect(() => {
    console.log("formate date  ", timeValue);
    console.log("ppoinment22 ", info);
  }, [formData]);
  const [dateValue, setDateValue] = useState();
  const [dateValuet, setDateValuet] = useState();

  const [timeValue, setTimeValue] = useState(dayjs());
  const [otpEnabled, setOtpEnabled] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log()
    if (typeof value == "object") {
      setFormData({
        ...formData,
        [name]: value[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const setDate = (newValue) => {
    setDateValue(newValue);

    // const date = dayjs(newValue).tz('UTC').format('YYYY-MM-DDTHH:mm:ss.SSZ');

    // console.log("date ",date)

    const date = dayjs(newValue).tz("UTC").format("YYYY-MM-DDTHH:mm:ss");

    console.log("date", date);

    // console.log("data ",date)
    setFormData((prev) => {
      return { ...prev, passoutDate: date };
      // return {...prev,date:date}
    });
  };

  const setDatet = (newValue) => {
    setDateValuet(newValue);

    // const date = dayjs(newValue).tz('UTC').format('YYYY-MM-DDTHH:mm:ss.SSZ');

    // console.log("date ",date)

    const date = dayjs(newValue).tz("UTC").format("YYYY-MM-DDTHH:mm:ss");

    console.log("date", date);

    // console.log("data ",date)
    setFormData((prev) => {
      return { ...prev, passoutDate: date };
      // return {...prev,date:date}
    });
  };

  const setTime = (newValue) => {
    setFormData((prev) => {
      if (newValue["$H"] == 20) {
        prev.hour = newValue["$H"] + 12;
      } else {
        prev.hour = newValue["$H"];
      }
      prev.minute = newValue["$m"];
      return { ...prev };
    });
    setTimeValue(newValue);
  };

  const handleTagsChange = (e) => {
    const { value } = e.target;

    console.log("val8e ", value);
    setFormData({
      ...formData,
      service: value,
    });
  };

  const gender = (e) => {
    setFormData((prev) => {
      let temp = { ...prev };
      if (e.target) {
        temp.gender = e.target.value;
      } else {
        temp.gender = e;
      }
      return { ...temp };
    });
  };

  const handleVerifymobileNumber = () => {
    if (formData.mobileNumber) {
      setOtpEnabled(true);
    }
  };

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(appnType, "appnType");

    const formDataobj = new FormData();
    Object.keys(formData).forEach((key, index) => {
      formDataobj.append(key, formData[key]);
    });
    const condition = appnType == "profile";
    const payload = {
...formData,
durationInYear:parseFloat(formData?.durationInYear),
      percentage: formData.percentage.includes(".")
        ? Number(formData.percentage)
        : Number(formData.percentage + ".00"),
       
      // "aadhar": formData?.aadhar
    };

    console.log("formdata", payload, " ", payload?.paymentPercentage);
    if (!formData?.id) {



      adDeduction(payload, () => {
        setOpen(false);
        getAll();
        // console.log("payload",)
      });
        
    } else {
      payload["id"] = modalData?.id;
      DeductionUp(payload, () => {
        getAll();
        setOpen(false);
        // getAll();
      });
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [servicesArr, setservicesArr] = useState([
    {
      serviceName: "Shaving",
      rate: "100.00/Per Head",
    },
    {
      serviceName: "Hair cutting",
      rate: "150.00/Per Head",
    },
  ]);
  const names = [
    "Haircut",
    "Hair Coloring",
    "Hair Styling",
    "Manicure",
    "Pedicure",
    "Facial",
    "Waxing",
    "Massage",
    "Makeup",
    "Bridal Makeup",
    "Threading",
    "Hair Spa",
    "Nail Art",
    "Skin Treatment",
  ];
  useEffect(() => {
    setFormData({
      ...formData,
      billerId: info.billerId,
    });
    setDate(timeValue);
    setTime(timeValue);
    gender("male");
  }, []);

  const infoD = useSelector((state) => state.reducer.detail);
  useEffect(() => {
    // getAlldetailSerice(
    //   {
    //     billerId: infoD?.billerId,
    //   },
    //   (res) => {
    //     console.log("Data res", res);
    //     setservicesArr(res?.data);
    //   }
    // );
  }, []);

  const [search, setSearchvalue] = useState("");
  const [searchModal, setSearchModal] = useState(false);
  const [cardValue, setCardValue] = useState(null);

  useEffect(() => {
    if (open === true && modalData) {
      console.log("modalData", modalData?.courseName);
      setFormData({
        ...formData,

        id: modalData?.id,
        employeeId:modalData?.employeeId, 
        courseName: modalData?.courseName,
        durationInYear: modalData?.durationInYear,
        passoutDate: modalData?.passoutDate,
        courseDetails: modalData?.courseDetails,
        percentage: modalData?.percentage
        ? String(modalData?.percentage)
        : "",
        grade: modalData?.grade,
        university: modalData?.university,
        institute: modalData?.institute
,
      });
      setDateValue(dayjs(modalData?.passoutDate));
      setDateValuet(dayjs(modalData?.passoutDate));

      // setDateValuet()
      // getDataRechageDetail(getNewSubscriptionReachageDetails, setSubscription);
    } else {
      console.log("close");

      setFormData({ ...struct });
    }
  }, [open]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
          height: "80vh",
        }}
      >
        <DialogTitle
          color="primary"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 'auto',
            p: "0px 10px !important"
          }}
        >
         Expence Detail {" "}
          <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => setOpen(false)} />
        </DialogTitle>
         
        <Box sx={{ mt: 4, my: 5 }} display="flex" justifyContent="center">
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
             
                 
                  <React.Fragment>

<Grid xs={12} item >
<TextFieldSearch label={"Search Employee"} search={search?.name} setOpen={setSearchModal} />          
</Grid>

<PoprchModal
          cardValue={cardValue}
          setCardValue={(data) => {
            setSearchvalue(data);
            setFormData({...formData,employeeId:data?.id})
            setCardValue(data);
          }}
          callback={() => {}}
          searchModal={searchModal}
          setSearchModal={setSearchModal}
        />
    
                  
                    {/* <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Employee Id"
                          name="employeeId"
                          value={formData.employeeId}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                     */}


                     




                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Course Name"
                          name="courseName"
                          value={formData.courseName}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Duration In Year"
                          name="durationInYear"
                          type="number"
                          value={formData.durationInYear}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            // Regular expression to allow only numbers and one decimal point
                            const regex = /^[0-9]{0,10}$/;

                            // Check if the input matches the regex
                            if (regex.test(inputValue)) {
                              handleChange(e);
                            }
                          }}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                   
             
                     
                    <Grid item xs={12}>
                      <FormControl fullWidth >

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Passout Date "
                            value={dateValue}
                            // minDate={dayjs()}
                            onChange={(newValue) => {
                              // setDateValue(date);
                              setDate(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                InputLabelProps={{ className: "mulish" }}
                                InputProps={{ classes: { input: "mulish" } }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                     </Grid> 

                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Course Details"
                          name="courseDetails"
                          value={formData.courseDetails}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    
                  </React.Fragment>
                
               
              
                  <React.Fragment>
                     
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label=" Percentage"
                          name="percentage"
                          value={formData.percentage}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            // Regular expression to allow only numbers and one decimal point
                            const regex = /^[0-9]*\.?[0-9]*$/;

                            // Check if the input matches the regex
                            if (regex.test(inputValue)) {
                              // setValue(inputValue);
                              handleChange(e);
                            }
                          }}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>  <FormControl fullWidth >
                        <TextField
                          label="Grade"
                          name="grade"
                          value={formData.grade}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                       
                    </Grid>
                    <Grid item xs={12} sm={12}>  <FormControl fullWidth >
                        <TextField
                          label="Institute"
                          name="institute"
                          value={formData.institute}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                       
                    </Grid>
                    <Grid item xs={12} sm={12}>  <FormControl fullWidth >
                        <TextField
                          label="University"
                          name="university"
                          value={formData.university}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                       
                    </Grid>
                  </React.Fragment>
                 





            
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#3695B6",
                    padding: "14px",
                    fontSize: "16px",
                  }}
                >
                  {modalData ? "Update" : "Add"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Modal>
  );
}
