import React, { useRef, useState } from "react";
import {
  Box,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";

import "react-datepicker/dist/react-datepicker.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { getCounterSalesStatement } from "./outstandingApi";
import { useEffect } from "react";

import DisplayOutStandingReport from "./displayOutStandingReport";
import OutStandingReportCart from "./outStandingReportCart";

import { downloadXLSXFile } from "../../utils/commonUtils";
import { api } from "../../utils/api";
import { getCounterSales } from "./outStandingReportCustomApiHandler";
import { displayOutstading } from "./outstandingReportFunction";
import PaymentReceiptSearchModal from "../paymentReceipt/paymentReceiptSearchModal";
import { getEmpearningSalary, getUnpaidUser } from "../paymentReceipt/paymentReceiptApi";
import PaymentReceipt from "../paymentReceipt/paymentReceipt";
import EmployeePayRollModal from "./employeeModal";
import PayrolSearchModal from "./payrollSearchModal";
import AdvancePayment from "../advancePayment/advancePayment";
import DynamicBreadcrumbs from "../../common/Breadcrumb";
import { useSelector } from "react-redux";
import Salary from "./salary";
import OutStandingSalaryReportCard from "./outStandingReportSalarycopy";
const EmployeePayRoll = () => {
  const [modalData, setModalData] = useState();
  const [outStandingBills, setOutStandingBills] = useState({
    records: [],
    totalOustandingAmount: "",
    totalRecords: "",
  });

  const [empSalary, setEmpSalary] = useState({
    records: [],
    totalOustandingAmount: "",
    totalRecords: "",
  });

  
  const [value, setValue] = React.useState("outstanding");

  const tabfn = (event, newValue) => {
    // setPageNo(1);

    setValue(newValue);
  };
  const businessChooseTab=useSelector((state)=>state.reducer.businessChooseTab)

  const [open, setOpen] = useState(false);
  //-----
  const [searchOutStandingsModal, setSearchOutStandingsModal] = useState(false);

  const [upPaidBillData, setUnPaidBillData] = useState();
  const [unPaidBill, setUnPaidBill] = useState([]);
  const data = {
    customerMobile: "",
    amount: 0,
    name: "",
  };
  const [billData, setBillData] = useState(data);
  const [billFilter, setBillFilter] = useState(null);
  const [USerObject, setUSerObject] = useState(null);

  const getUnpaid = async (param) => {
    await getUnpaidUser(param)
      .then((res) => {
        console.log("unPaid user", res?.data?.data[0]);

        setUnPaidBillData(res?.data?.data[0]);
      })
      .catch((err) => {});
     
  };


  
  const getEmpSalary=async()=>{
    
  }

  //----
  const filters = [
    { name: "Amount Greater than ", value: "amountgreatethan" },
    { name: "Amount Less than ", value: "amountlessthan" },
    { name: "Amount elapsed than ", value: "dayselapsed" },
    { name: "Partially Paid ", value: "partlypaid" },
  ];

  ///---pagination functions
  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    records: 0,
    pageSize: 24,
  });
  const [payloadGetCounterSales, setPayloadGetCounterSales] = useState({
    page: 1,
    pageSize: pagination.pageSize,
    sortBy: "",
    order: 0,
    buyerGroupId: 0,
    filter: "",
    search: "",
  });
  const [numberOfRecords, setNumberOfRecords] = useState(1);
  const handleChange = (event, value) => {
    getCounterSales(
      {
        ...payloadGetCounterSales,
        page: value,
        pageSize: pagination.pageSize,
      },
      outStandingBills,
      getCounterSalesStatement,
      setNumberOfRecords,
      setOutStandingBills
    );
    setPagination({
      ...pagination,
      page: value,
    });
  };

  const [search, setSearch] = useState("");


  const [collection,setCollection]=useState([])

  // const [value, setValue] = useState("");
  useEffect(() => {
    console.log("search ", search);
    getCounterSales(
      {
        employeeMasterId: USerObject?.employeeMasterId,
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
      outStandingBills,
      getCounterSalesStatement,
      setNumberOfRecords,
      setOutStandingBills
    );
    getDataEmpEarn({ 

      employeeMasterId: USerObject?.employeeMasterId,
      page: pagination.page,
      pageSize: pagination.pageSize,
    })
  }, [USerObject]);
  const getDataEmpEarn=async(param)=>{
    await getEmpearningSalary(param).then((res)=>{
      console.log("data======= ",res," ",res?.data?.data) 
      setCollection(res?.data?.data?.counterSalesHeader)
    }) .catch((err)=>{
      console.log("error =r ",err)
    })
  }
useEffect(()=>{



},[])
  return (
    <>
      <DynamicBreadcrumbs />
      <Box
        sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}
      >
        <Grid
          container
          sx={{
            overflowY: "auto",
            // height: "calc(100vh - 70px)",
            maxHeight: { sm: "calc(100vh - 70px)", xs: "calc(100vh - 130px)" },
          }}
          spacing={1}
        >
          <Grid xs={12}>
          
          </Grid>
          {businessChooseTab?.value == "settledandpaidAmount" && (
            <React.Fragment>
              <Grid item xs={12} sm={12}>
                <Box
                  // disabled
                  // size="small"
                  readonly
                  fullWidth
                  onClick={() => {
                    // setSearchOutStandingsModal(true)
                  }}
                  // id="name"
                  // label="Search by Name/Mobile Number"
                  // value={search}
                  sx={{
                    display: "flex",
                    height: "23px",
                    justifyContent: "space-between",
                    // width: "70%",
                    border: "1px solid #c9c9c9",
                    p: 1,
                    borderRadius: "4px ",
                    color: "grey",
                    "&:hover": {
                      cursor: "pointer !important",
                    },
                  }}
                  onChange={(e) => {
                    // setSearch(e.target.value);
                  }}
                >
                  <Typography variant="p">
                    {search !== "" ? search : "Search by Name/Mobile Number"}
                  </Typography>
                  <SearchIcon
                    onClick={() => {
                      // setSearchModal(true)
                      setSearchOutStandingsModal(true);
                    }}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                }}
              >
                {" "}
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                }}
              >
                {" "}
                <Fab
                  onClick={() => {
                    // setOpen(true);

                    setOpen(true);
                  }}
                  sx={{
                    textTransform: "capitalize",
                    width: {
                      xs: "100%",
                      sm: "auto",
                    },
                    // p: "10px",
                    whiteSpace: "nowrap",
                  }}
                  size="small"
                  color="primary"
                  variant="extended"
                  aria-label="add"
                >
                  Add Purchase
                  <AddIcon />
                </Fab>
              </Grid>

              <Grid item xs={12}>
                <Divider
                  sx={{
                    // width: "100%",
                    background: (theme) => theme.palette.primary.main,
                  }}
                />
              </Grid>
              <Divider
                sx={{
                  // width: "100%",
                  background: (theme) => theme.palette.primary.main,
                }}
              />

              <Grid
                container
                sx={{
                  marginX: "20px",
                }}
              >
                {outStandingBills?.records?.map((item, i) => {
                  console.log("Outstanding item ", item);
                  // if (
                  //   !item?.buyerName?.toLowerCase().includes(search.toLowerCase()) &&
                  //   !item?.buyerMobile
                  //     ?.toLowerCase()
                  //     .includes(search.toLowerCase()) &&
                  //   search.length > 0
                  // ) {
                  //   return null;
                  // }
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      key={"payamentRecpt" + i}
                      sx={{ p: "5px", borderRight: "3px solid #dfdfdf" }}
                    >
                      {/* <OutStandingReportCart
                        item={item}
                        setOpen={setOpen}
                        setModalData={setModalData}
                      /> */}
                       <OutStandingSalaryReportCard
                        item={item}
                        setOpen={setOpen}
                        setModalData={setModalData}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              {/* <DisplayOutStandingReport
              modalData={modalData}
              open={open}
              setOpen={setOpen}
              getRecept={() => {
                // getPaymentRec({ ...getPaymentObj });
              }}
            /> */}
              {/* -------- */}
              <Grid container sx={{ pt: "10px" }}>
                <Grid item xs={4}></Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    // bgcolor: "red",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* <Stack direction="row" justifyContent="space-around" sx={{ mt: 2 }}> */}
                  <Pagination
                    count={
                      Number.isInteger(numberOfRecords / pagination.pageSize)
                        ? numberOfRecords / pagination.pageSize
                        : parseInt(numberOfRecords / pagination.pageSize) + 1
                    }
                    page={pagination.page}
                    onChange={handleChange}
                  />
                  {/* </Stack> */}
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </React.Fragment>
          )}
          {businessChooseTab?.value == "AdvancePayment" && <AdvancePayment />}
          {businessChooseTab?.value == "Salary" && <>
          {true && (
            <React.Fragment>
              <Grid item xs={12} sm={12}>
                <Box
                  // disabled
                  // size="small"
                  readonly
                  fullWidth
                  onClick={() => {
                    // setSearchOutStandingsModal(true)
                  }}
                  // id="name"
                  // label="Search by Name/Mobile Number"
                  // value={search}
                  sx={{
                    display: "flex",
                    height: "23px",
                    justifyContent: "space-between",
                    // width: "70%",
                    border: "1px solid #c9c9c9",
                    p: 1,
                    borderRadius: "4px ",
                    color: "grey",
                    "&:hover": {
                      cursor: "pointer !important",
                    },
                  }}
                  onChange={(e) => {
                    // setSearch(e.target.value);
                  }}
                >
                  <Typography variant="p">
                    {search !== "" ? search : "Search by Name/Mobile Number"}
                  </Typography>
                  <SearchIcon
                    onClick={() => {
                      // setSearchModal(true)
                      setSearchOutStandingsModal(true);
                    }}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                }}
              >
                {" "}
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                }}
              >
                {" "}
                <Fab
                  onClick={() => {
                    // setOpen(true);

                    setOpen(true);
                  }}
                  sx={{
                    textTransform: "capitalize",
                    width: {
                      xs: "100%",
                      sm: "auto",
                    },
                    // p: "10px",
                    whiteSpace: "nowrap",
                  }}
                  size="small"
                  color="primary"
                  variant="extended"
                  aria-label="add"
                >
                  Add Purchase
                  <AddIcon />
                </Fab>
              </Grid>

              <Grid item xs={12}>
                <Divider
                  sx={{
                    // width: "100%",
                    background: (theme) => theme.palette.primary.main,
                  }}
                />
              </Grid>
              <Divider
                sx={{
                  // width: "100%",
                  background: (theme) => theme.palette.primary.main,
                }}
              />

              <Grid
                container
                sx={{
                  marginX: "20px",
                }}
              >
                {collection?.map((item, i) => {
                  console.log("Outstanding item ", item);
                  // if (
                  //   !item?.buyerName?.toLowerCase().includes(search.toLowerCase()) &&
                  //   !item?.buyerMobile
                  //     ?.toLowerCase()
                  //     .includes(search.toLowerCase()) &&
                  //   search.length > 0
                  // ) {
                  //   return null;
                  // }
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      key={"payamentRecpt" + i}
                      sx={{ p: "5px", borderRight: "3px solid #dfdfdf" }}
                    >
                      <OutStandingSalaryReportCard
                        item={item}
                        setOpen={setOpen}
                        setModalData={setModalData}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              {/* <DisplayOutStandingReport
              modalData={modalData}
              open={open}
              setOpen={setOpen}
              getRecept={() => {
                // getPaymentRec({ ...getPaymentObj });
              }}
            /> */}
              {/* -------- */}
              <Grid container sx={{ pt: "10px" }}>
                <Grid item xs={4}></Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    // bgcolor: "red",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* <Stack direction="row" justifyContent="space-around" sx={{ mt: 2 }}> */}
                  <Pagination
                    count={
                      Number.isInteger(numberOfRecords / pagination.pageSize)
                        ? numberOfRecords / pagination.pageSize
                        : parseInt(numberOfRecords / pagination.pageSize) + 1
                    }
                    page={pagination.page}
                    onChange={handleChange}
                  />
                  {/* </Stack> */}
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </React.Fragment>
          )}
          </>}
          {/* //------------------------ */}
        </Grid>
      </Box>

      <EmployeePayRollModal
        modalData={modalData}
        open={open}
        setOpen={setOpen}
      />
      {/* <PaymentReceiptSearchModal
        searchModal={searchOutStandingsModal}
        setSearchModal={setSearchOutStandingsModal}
        unPaidBill={unPaidBill}
        getUnpaid={getUnpaid}
        setBillData={setBillData}
        search={search}
        setSearch={setSearch}

        setUnPaidBill={setUnPaidBill}
        setUnPaidBillData={setUnPaidBillData}
        setPagination={setPagination}
        billData={billData}
        callSearchApi={(searchName) => {
          setBillFilter("")
          // payloadGetCounterSales.search
          setPayloadGetCounterSales({ ...payloadGetCounterSales, search: "" })
          getCounterSales(
            {
              page: 1,
              pageSize: 10000,
              sortBy: "",
              order: 0,
              buyerGroupId: 0,
              filter: "buyername",
              search: searchName,
            },
            outStandingBills,
            getCounterSalesStatement,
            setNumberOfRecords,
            setOutStandingBills
          );
          // getPaymentRec(
          //   {
          //     // ...getPaymentObj,
          //     // page: pagination.page,
          //     // pageSize: pagination.pageSize,

          //       "page": 1,
          //       "pageSize": 10000,
          //       "filter": "buyername",//"filter": null,
          //       "search": searchName,
          //       "date": getPaymentObj?.date

          //   },
          //   getPaymentReceipt,
          //   setNumberOfRecords,
          //   setTotals,
          //   totals,
          //   setReceipts
          // );
        }}
      //  userDetail={userDetail}
      /> */}
      <PayrolSearchModal
        searchModal={searchOutStandingsModal}
        setSearchModal={setSearchOutStandingsModal}
        unPaidBill={unPaidBill}
        getUnpaid={getUnpaid}
        setBillData={setBillData}
        search={search}
        setSearch={setSearch}
        setUSerObject={(data) => {
          setUSerObject(data);
        }}
        setUnPaidBill={setUnPaidBill}
        setUnPaidBillData={setUnPaidBillData}
        setPagination={setPagination}
        billData={billData}
        callSearchApi={(searchName) => {
          setBillFilter("");
          // payloadGetCounterSales.search
          setPayloadGetCounterSales({ ...payloadGetCounterSales, search: "" });
          getCounterSales(
            {
              page: 1,
              pageSize: 10000,
              sortBy: "",
              order: 0,
              buyerGroupId: 0,
              filter: "buyername",
              search: searchName,
            },
            outStandingBills,
            getCounterSalesStatement,
            setNumberOfRecords,
            setOutStandingBills
          );
          // getPaymentRec(
          //   {
          //     // ...getPaymentObj,
          //     // page: pagination.page,
          //     // pageSize: pagination.pageSize,

          //       "page": 1,
          //       "pageSize": 10000,
          //       "filter": "buyername",//"filter": null,
          //       "search": searchName,
          //       "date": getPaymentObj?.date

          //   },
          //   getPaymentReceipt,
          //   setNumberOfRecords,
          //   setTotals,
          //   totals,
          //   setReceipts
          // );
        }}
        //  userDetail={userDetail}
      />
      {/* </Box> */}
      {/* </Drawer> */}
    </>
  );
};

export default EmployeePayRoll;
