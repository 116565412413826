import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

export default function DynamicBreadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);


  const businessChooseTab=useSelector((state)=>state.reducer.businessChooseTab) 
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb" sx={{px:5, pt:1}}>
        <Link underline="hover" color="inherit" component={RouterLink} to="/">
          Home
        </Link>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;

          return isLast ? (
            <Typography key={to} color="text.primary">
              {(function(){
    let da = value.split(/(?=[A-Z])/);  // Split the string at capital letters
    let data = "";

    // Loop through each word and format it
    da.forEach((ele, index) => {
        data += ele + " ";
        if (data.charAt(0) === data.charAt(0).toLowerCase()) {
            // Convert the first letter to uppercase
            let firstLetterUppercase = data.charAt(0).toUpperCase() + data.slice(1);

            // Get the ASCII value of the first letter after conversion
            let asciiValue = firstLetterUppercase.charCodeAt(0);
            data = firstLetterUppercase;
        }
    });

    console.log("dataexpence", data);

    // Check if the word is "expence" (correct to "expense")
    if (data.trim() === "Expence") {
        return "Expense";
    }

    return data; // Remove trailing space from the result
})()
}
            </Typography>
          ) : (
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to={to}
              key={to}
            >
              {value}
            </Link>
          );

        })}
        {businessChooseTab?.value && 
        <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              
            >
              {(function(){
                let da=businessChooseTab?.label.split(/(?=[A-Z])/)
                let data=""
                da.forEach((ele)=>{
                  data+=ele+" "
                  if (data.charAt(0) === data.charAt(0).toLowerCase()) {
                    // Convert the first letter to uppercase
                    let firstLetterUppercase = data.charAt(0).toUpperCase() + data.slice(1);
                    
                    // Get the ASCII value of the first letter after conversion
                    let asciiValue = firstLetterUppercase.charCodeAt(0);
                    data=firstLetterUppercase 
                } 
                })
                return data
              })()}
              { }
            </Link>

        }
      </Breadcrumbs>
    </div>
  );
}
