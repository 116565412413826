

import { useNavigate } from "react-router";
import "./login.css";

import qs from "qs";
import store from "../../app/store/store";

import { stopLoading } from "../../app/store/reducer";
import { encrypt } from "../../utils/encryption";
import Swal from "sweetalert2";
import { LoginApi } from "./loginApi";

import logo from "../../assets/headers/logoSMB.png";
import { swalAlertPopProps } from "../../utils/commonUtils";
import { ENCRYPTIONKEY } from "../../utils/api";

// import { loginValidation } from "./loginValidationFunction";
import { routePath } from "../../constants/routePath";
import React, { useState } from "react";
import {
  TextField,
  Box,
  Button,
  Typography,
  Container,
  Grid,
  Card,
} from "@mui/material";
import { Link } from "react-router-dom";
import LogoImage from "../../assets/img/logo.svg";
import { loginValidation } from "../login/loginValidationFunction";
export default function UserLogin() {
  const navigate = useNavigate();

  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [mobileNumber, setMobileNumber] = useState("");

  function generateRandomString(length) {
    const chars = "0123456789"; // Change this to include other characters if needed
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      result += chars[randomIndex];
    }
    return result;
  }

  const [loginData, setLoginData] = useState({
    username: "",
    grant_type: "implicit",
    client_id: "client",
    ismpinauth: "1",
    mpin: "",
    // mpin: "",
    password: '""',
    loggedinweb: "0",
  });
  const handleChange = (value, index) => {
    let newOtp = [...otp];
    if (/^[0-9]?$/.test(value)) {
      newOtp[index] = value;
      setOtp(newOtp);
    }
  };

  const handleKeyUp = (e, index) => {
    const nextInput = document.querySelector(`input[name=otp${index + 1}]`);
    const prevInput = document.querySelector(`input[name=otp${index - 1}]`);

    if (e.key === "Backspace" && prevInput) {
      prevInput.focus();
    } else if (nextInput && e.target.value !== "") {
      nextInput.focus();
    }
  };


    const handleForgetMPINClick = () => {
    if (mobileNumber.length !== 10) {
      Swal.fire({
        text: "Please Enter Phone Number",
        icon: "warning",
        ...swalAlertPopProps,
      });
    } else {
      navigate("/otpforget"); // Navigate to /otpforget when mobileNumber is valid
    }
  };


  const handleSubmit = async () => {
    const obj = { ...loginData };
    const val = loginValidation({...loginData,username:mobileNumber,mpin:otp.join("")});
    if (val?.status) {
      console.log("mpin login ", loginData.mpin);
      const randomString = generateRandomString(4);
      const randomId = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, '0');
      obj.username = encrypt(mobileNumber + randomId, ENCRYPTIONKEY);
      obj.mpin = encrypt(otp.join("") + randomId, ENCRYPTIONKEY);

      let loginTmp = qs.stringify(obj);

      await LoginApi(loginTmp)
        .then(async (res) => {
          await sessionStorage.setItem("smbToken", res?.data?.access_token);
          navigate(routePath.DASHBOARD);
          
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              title: "Invalid Username or Password",
              text: "Please enter a valid username and password",
              icon: "error",

              ...swalAlertPopProps,
            });
            store.dispatch(stopLoading());
          }
        });
    } else {
      Swal.fire({
        title: val.title,
        text: val.text,
        icon: "warning",

        ...swalAlertPopProps,
      });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };
  return (
    <Container maxWidth="lg" sx={{ padding: { xs: "10px", sm: "20px" } }}>
    <Card
      sx={{
        padding: { xs: "10px", sm: "20px" },
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ height: "100%", flex: 1 }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img
              src={LogoImage}
              alt="logo"
              style={{
                maxWidth: "100%",
                height: "auto",
                width: { xs: "80%", sm: "100%" },
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
           
            // onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography variant="h4" gutterBottom sx={{ color: "#3695B6" }}>
              Log In
            </Typography>
            <TextField
              label="Mobile Number"
              required
              type="tel"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              sx={{ mb: 2, width: { xs: "100%", sm: "22.3rem" } }}
              inputProps={{
                maxLength: 10,
                inputMode: "numeric",
                pattern: "[0-9]*",
                style: { fontSize: "18px" },
              }}
              variant="outlined"
            />
           <Box> 
           <Typography>Enter MPIN</Typography>
           </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                mb: 2,
              }}
            > 

              {otp.map((value, index) => (
                <TextField
                  key={index}
                  name={`otp${index}`}
                  type="text"
                  value={value}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyUp={(e) => handleKeyUp(e, index)}
                  inputProps={{
                    maxLength: 1,
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    style: {
                      textAlign: "center",
                      fontSize: "24px",
                      width: "40px",
                      height: "40px",
                    },
                  }}
                  variant="outlined"
                  margin="dense"
                />
              ))}
            </Box>
            <Box marginTop={1}>
              <Typography 
              > <Link
              style={{ 
                color: "#2096A4",
                cursor: "pointer",
                fontWeight: "500",
              }}


              to={mobileNumber.length === 10?routePath.OTPFORGET:null}
              onClick={handleForgetMPINClick}
              
              
            >
              Forget MPIN
            </Link>
            
              </Typography>
            </Box>
            <Button
              variant="contained"
              
              sx={{
                backgroundColor: "#3695B6",
                cursor: "pointer",
                fontSize: "18px",
                fontWeight: "600",
                marginTop: "10px",
                width: { xs: "100%", sm: "22.3rem" },
              }}
              onClick={()=>{
                handleSubmit()
              }}
            >
              Log In
            </Button>
            <Box marginTop={1}>
              <Typography 
              > 
                {/* <Link
                  style={{ 
                    color: "#2096A4",
                    cursor: "pointer",
                    fontWeight: "500",
                  }}
                >
                 Associate User Login
                </Link> */}
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography 
              > 
              New to Elegant Prime? {" "}
                <Link
                to={routePath.REGISTRATION}
                  style={{ 
                    textDecoration:"none",
                    color: "#2096A4",
                    cursor: "pointer",
                    fontWeight: "600", 
                  }}
                >
                 click here to sign up!
                </Link>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  </Container>
  );
}
