import React, { useEffect, useRef, useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import EventIcon from "@mui/icons-material/Event";

import {
  Box,
  Divider,
  Fab,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {
  getAccountReachageDetails,
  getAllEmployeeList,
} from "./appoinmentDashboardApi";
import SmbAccountReachargeCard from "./AppoinmentDashboardCard";
import AddIcon from "@mui/icons-material/Add";
import { getData } from "./smbAccountRechargeCustomApiHandler";

import {
  convertDateToCustomFormat,
  getFirstDateOfMonth,
  getStartOfMonth,
} from "../../utils/commonUtils";
import AppoinmentDashboardCard from "./AppoinmentDashboardCard";
import { DatePicker } from "@mui/x-date-pickers";
import DatePickerComponent from "../../common/curstomDatePicker";
import FormModal from "./FormModal";
import DynamicBreadcrumbs from "../../common/Breadcrumb";
import { useSelector } from "react-redux";
import EmployeeDetail from "./educationalDetail";
import ExpenceEmp from "./expenceEmp";

export default function EmployeeInfo({ gridSize }) {
  // const [data, setData] = useState();
  const [open, setOpen] = useState(false);

  const datePickerRef = useRef();
  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  const [date, setDate] = useState(getStartOfMonth());

  const [SelectedStartDate, setSelectedStartDate] = useState(
    getFirstDateOfMonth()
  );
  const [modalData, setModalData] = useState(null);
  const [value, setValue] = React.useState("Unassigned");
  const [data, setData] = useState();
  const [valuet, setValuet] = React.useState("Assigned");
  const businessChooseTab=useSelector((state)=>state.reducer.businessChooseTab)


  const handleChange = (event, newValue) => {
    // setPageNo(1);

    setValue(newValue);
  };
  const handleChanget = (event, newValue) => {
    // setPageNo(1);

    setValuet(newValue);
  };

  useEffect(() => {
    getAllEmployeeList((res) => {
      console.log("getAllEmployeeList", res);
      setData(res?.list);
    });
    // getData(getAccountReachageDetails, setData);
  }, []);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);

  const handleOpenDatePicker = () => {
    setIsDatePickerOpen(true);
  };

  const handleCloseDatePicker = () => {
    setIsDatePickerOpen(false);
  };
  useEffect(() => {
    console.log("modalData parent", modalData);
  }, [modalData]);
  useEffect(() => {
    if (open == false) {
      setModalData({});
    }
  }, [open]);
  return (
    <>
      <DynamicBreadcrumbs />
      { businessChooseTab?.value=="PersnalInfo" && <>
        <Box
        sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            {" "}
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            {" "}
            {!gridSize && (
              <Fab
                onClick={() => {
                  // setOpen(true);

                  setOpen(true);
                }}
                sx={{
                  zIndex: "0",
                  textTransform: "capitalize",
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                  // p: "10px",
                  whiteSpace: "nowrap",
                }}
                size="small"
                color="primary"
                variant="extended"
                aria-label="add"
              >
                Add Employee
                <AddIcon />
              </Fab>
            )}
          </Grid>

       
        <>  <Grid container>
            {data?.map((item, index) => {
              return (
                <AppoinmentDashboardCard
                  item={item}
                  key={"anualCard" + index}
                  onClick={(data) => {
                    setModalData(item);
                    setOpen(true);
                  }}
                />
              );
            })}
          </Grid>
          <FormModal
            open={open}
            setOpen={setOpen}
            getAll={() => {
              getAllEmployeeList((res) => {
                console.log("getAllEmployeeList", res);
                setData(res?.list);
              });
            }}
            modalData={modalData}
          />
          </>
           
        </Grid>
      </Box> </>}
      {/* {
        businessChooseTab?.value=="PersnalInfo" &&
        <>
        <EmployeeDetail /> 
        </>
      } */}
        {
        businessChooseTab?.value=="EducatoinalDetail" &&
        <>
        <EmployeeDetail /> 
        </>
      }
      {businessChooseTab?.value=="expence" &&
      <ExpenceEmp/>



      }
    </>
  );
}
