import Swal from "sweetalert2";
import { ADDEDUCATIOIN, ADDEMPLOYEE, ADDEXPERINCEDETAIL, GETACCOUNTRECHAGEDETAILS, GETALLEDUCATIONALDETAILS, GETALLEXPERINCEDETAIL, GETCUSTOMERAPPOINMENTS, GETEMPLOYEE, GETRENEWSUBSCRIPTIONDETAIL, UPDATEEMPLOYEE, UPDATEEXPERINCEDETAIL, UPDAT__DUCATIOIN } from "../../utils/api"
import axios from "../../utils/axios"
import { swalAlertPopProps } from "../../utils/commonUtils";

export const getAppoinment=async()=>{
    const res=await axios.get(GETCUSTOMERAPPOINMENTS)
    return res;
}
export const getNewSubscriptionReachageDetails=async()=>{
    const res=await axios.get(GETRENEWSUBSCRIPTIONDETAIL)
    return res;
}

// GETRENEWSUBSCRIPTIONDETAIL



export const getAllEmployeeList=async(callback)=>{
    await axios
    .get(GETEMPLOYEE)
    .then((res) => {
      console.log("res search ", res);
      callback(res.data.data) 
    })
    .catch((err) => {
      console.log(err);
    });
}

// const 
const validation=(obj)=>{
  // let isEmpty=emptyField(obj);
  let typeValid={
    isEmpty:emptyField(obj)
  }
  if(typeValid.isEmpty){
    console.log("empty key",typeValid)
  }
}

const emptyField=(obj)=>{
  Object.keys(obj).forEach((key)=>{
    if(obj[key]?.trim().length==0){
      return key;
    }
  })
  return false;
}

export const addEmployee=async(payload,callback)=>{
  // validation(payload)
  await axios
  .post(ADDEMPLOYEE,payload)
  .then((res) => {
    console.log("res search ", res);
    
    callback(res.data.data)
    if(res?.data?.succeeded) {
      Swal.fire({
        text:"Successfully added" ,
        icon: "success",
        ...swalAlertPopProps,
      });
    }






    else{
      Swal.fire({
        text:res?.data?.message ,
        icon: "error",
        ...swalAlertPopProps,
      });
    }
  })
  .catch((err) => {
    console.log(err);
    Swal.fire({
      text:JSON.stringify(err) ,
      icon: "success",
      ...swalAlertPopProps,
    });
  });
}


export const updateEmployee=async(payload,callback)=>{
  // validation(payload)
  await axios
  .post(UPDATEEMPLOYEE,payload)
  .then((res) => {
    console.log("res search ", res);
    
    callback(res.data.data)
    if(res?.data?.succeeded) {
      Swal.fire({
        text:"Successfully updated" ,
        icon: "success",
        ...swalAlertPopProps,
      });
    }






    else{
      Swal.fire({
        text:res?.data?.message ,
        icon: "error",
        ...swalAlertPopProps,
      });
    }
  })
  .catch((err) => {
    console.log(err);
    Swal.fire({
      text:JSON.stringify(err) ,
      icon: "success",
      ...swalAlertPopProps,
    });
  });
}

  
    export const getAlleducatio =async(payload,callback)=>{
  await axios.post(GETALLEDUCATIONALDETAILS,payload).then((res)=>{
    callback(res)
  }).catch((err)=>{
    console.log(err)
  })
}

export const adDeduction =async(payload,callback)=>{
  await axios.post(ADDEDUCATIOIN,payload).then((res)=>{
    callback(res)
    if(res?.data?.succeeded) {
      Swal.fire({
        text:"Successfully added" ,
        icon: "success",
        ...swalAlertPopProps,
      });
    }






    else{
      Swal.fire({
        text:res?.data?.message ,
        icon: "error",
        ...swalAlertPopProps,
      });
    }
  }).catch((err)=>{
    console.log(err)
  })
}

export const ExperienceUp =async(payload,callback)=>{
  await axios.post(UPDAT__DUCATIOIN,payload).then((res)=>{
    callback(res)
    if(res?.data?.succeeded) {
      Swal.fire({
        text:"Successfully updated" ,
        icon: "success",
        ...swalAlertPopProps,
      });
    }






    else{
      Swal.fire({
        text:res?.data?.message ,
        icon: "error",
        ...swalAlertPopProps,
      });
    }
  }).catch((err)=>{
    console.log(err)
  })
}
export const expeinceAdd =async(payload,callback)=>{
  await axios.post(ADDEXPERINCEDETAIL,payload).then((res)=>{
    callback(res)
    if(res?.data?.succeeded) {
      Swal.fire({
        text:"Successfully Added" ,
        icon: "success",
        ...swalAlertPopProps,
      });
    }






    else{
      Swal.fire({
        text:res?.data?.message ,
        icon: "error",
        ...swalAlertPopProps,
      });
    }
  }).catch((err)=>{
    console.log(err)
  })
}

export const Updateexpeince =async(payload,callback)=>{
  await axios.post(UPDATEEXPERINCEDETAIL,payload).then((res)=>{
    callback(res)
    if(res?.data?.succeeded) {
      Swal.fire({
        text:"Successfully updated" ,
        icon: "success",
        ...swalAlertPopProps,
      });
    }






    else{
      Swal.fire({
        text:res?.data?.message ,
        icon: "error",
        ...swalAlertPopProps,
      });
    }
  }).catch((err)=>{
    console.log(err)
  })
}

export const DeductionUp =async(payload,callback)=>{
  await axios.post(UPDAT__DUCATIOIN,payload).then((res)=>{
    callback(res)
    if(res?.data?.succeeded) {
      Swal.fire({
        text:"Successfully updated" ,
        icon: "success",
        ...swalAlertPopProps,
      });
    }






    else{
      Swal.fire({
        text:res?.data?.message ,
        icon: "error",
        ...swalAlertPopProps,
      });
    }
  }).catch((err)=>{
    console.log(err)
  })
}



export const getAllPerice =async(payload,callback)=>{
  await axios.get(GETALLEXPERINCEDETAIL).then((res)=>{
    callback(res)
  }).catch((err)=>{
    console.log(err)
  })
}

