import {
  Box,
  Fab,
  Grid,
  InputAdornment,
  Pagination,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getItems } from "./itemApi";
import ItemCard from "./ItemCard";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add";
import AddItem from "./AddItem";
import { setSnackbar } from "../../app/store/reducer";
import { useDispatch } from "react-redux";
import { setPageSizeScreenWise } from "./itemServicesFunction";
export default function ItemServices() {
  const [items, setItmes] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, total: 1 });
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [editedItem, setEditedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const isLargeScreen = useMediaQuery("(min-width: 1280px)"); // 'lg' breakpoint
  const isMediumScreen = useMediaQuery(
    "(min-width: 960px) and (max-width: 1279px)"
  ); // 'md' breakpoint

  // };
  useEffect(() => {
    let pageSize = setPageSizeScreenWise(isLargeScreen, isMediumScreen);
    getItems({ pagination, pageSize }).then((res) => {
      setItmes(res.data.data);
      setPagination({
        page: res.data.pageNumber,
        total: res.data.totalPages,
      });
      setTotalItems(res.data.totalRecords);
    });
  }, []);
  useEffect(() => {
    let pageSize = setPageSizeScreenWise(isLargeScreen, isMediumScreen);

    getItems({ pagination, search, pageSize }).then((res) => {
      setItmes(res.data.data);
      setPagination({
        ...pagination,
        page: search ? 1 : pagination.page,
        total: res.data.totalPages,
      });
      setTotalItems(res.data.totalRecords);
    });
  }, [pagination.page, search]);
  const handleChange = (event, value) => {
    setPagination({
      ...pagination,
      page: value,
    });
  };
  return (
    <Box
      sx={{
        overflowY: "auto",
        height: {
          lg: "calc(100vh - 70px)",
          md: "calc(100vh - 70px)",
          sm: "calc(100vh - 70px)",
          xs: "calc(100vh - 130px)",
        },
      }}
    >
      <Stack direction="row" spacing={2} justifyContent="end">
    

        <TextField
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          sx={{ width: "400px" }}
          size="small"
          id="outlined-basic-search"
          placeholder="Search Items"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {search ? (<CloseIcon onClick={()=>{setSearch("")}} sx={{cursor:'pointer'}}/>) : (<SearchIcon />)}
              </InputAdornment>
            ),
          }}
        />
        <Fab
          onClick={() => {
            setOpen(true);
          }}
          sx={{
            textTransform: "capitalize",
            width: {
              xs: "100%",
              sm: "auto",
            },
            // p: "10px",
            whiteSpace: "nowrap",
                 zIndex:'0'
          }}
          size="small"
          color="primary"
          variant="extended"
          aria-label="add"
        >
          Add Item
          <AddIcon />
        </Fab>
      </Stack>
      <Grid container spacing={2} sx={{
        mt: 2, width: "100%",
      }}>
        {items.map((item) => {
          // return null
          return (
            <Grid item lg={3} sm={6} xs={12} md={4}>
              <Paper>
                <ItemCard
                  setEditedItem={() => {
                    setEditedItem(item);
                    setOpen(true);
                  }}
                  item={item}
                />
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      <Grid container sx={{ pt: "10px" }}>
        <Grid item xs={4}>
          <Typography color="primary">
            <strong>Total Items: &nbsp;</strong>
            {totalItems}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            // bgcolor: "red",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <Stack direction="row" justifyContent="space-around" sx={{ mt: 2 }}> */}
          <Pagination
            count={pagination.total}
            page={pagination.page}
            onChange={handleChange}
            sx={{ marginBottom: { xs: 2 } }}
          />
          {/* </Stack> */}
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <AddItem
        open={open}
        item={editedItem}
        setEditedItem={setEditedItem}
        setOpen={setOpen}
        onSave={() => {
          let pageSize = setPageSizeScreenWise(isLargeScreen, isMediumScreen);
          getItems({ pagination, pageSize }).then((res) => {
            setItmes(res.data.data);
            setPagination({
              page: res.data.pageNumber,
              total: res.data.totalPages,
            });
            setTotalItems(res.data.totalRecords);
          });
          setOpen(false);
          dispatch(
            setSnackbar({
              open: true,
              message: "Item saved successfully",
            })
          );
        }}
      />
    </Box>
  );
}
