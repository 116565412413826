import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

const Timer_opt = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [timer, setTimer] = useState(120); // Start with 120 seconds (2 minutes)

  useEffect(() => {
    // Set interval to decrement timer every second
    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1; // Decrement the timer
        } else {
          clearInterval(timerInterval); // Stop the interval when it reaches 0
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timerInterval); // Cleanup interval on component unmount
  }, []);

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options).replace(/,/g, "");
  };

  // Calculate minutes and seconds from the timer
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  return (
    <Box
      textAlign="center"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        borderRadius: "8px",
        padding: "0px 10px",
      }}
    >
      

      {/* Display countdown in "min sec" format */}
      <Typography
        color="primary.main"
        variant="subtitle2"
        margin={0}
        fontWeight={"bold"}
        sx={{ fontSize: { xs: '12px', lg: '0.875rem' } }}
      >
        {`${minutes} : ${seconds < 10 ? `0${seconds}` : seconds}`}
      </Typography>
    </Box>
  );
};

export default Timer_opt;