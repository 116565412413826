import {
    Box,
    Button,
    DialogTitle,
    Divider,
    ListItemText,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import { useState } from "react";
  
  export const PaymentmodeModal = ({
    
    open,
    setOpen,callback

    
  }) => {
    const [data, setData] = useState({
      paymentMethod: "Online", // Fixed key spelling: paymentMethod
      transactionDetails: "",
    });
  
    const handleTagsChange = (e) => {
      const { value } = e.target;
      setData({
        ...data,
        paymentMethod: value, // Corrected typo from paymentMedthod to paymentMethod
      });
    };
  
    return (
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            background: "white",
            padding:"10px",
            width: "52%",
            overflowY: "auto",
            position: "absolute",
            left: "25%",
            top: "10%",
            zIndex:"999999999"
          }}
        >
          <DialogTitle
            color="primary"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: "0px 10px !important",
            }}
          >
            Employee Payroll Details
            <CloseIcon
              sx={{ float: "right", cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
          </DialogTitle>
          <Divider />
          <Box sx={{ mt: 2,display:'flex',alignItems:'center' }}>
            <Typography>Payment Method </Typography>
            <Select
            sx={{
                margin:'0px 10px '
            }}
              labelId="tags-label"
              id="services"
              name="tags"
              value={data?.paymentMethod}
              onChange={handleTagsChange}
              input={<OutlinedInput label="Payment Method" />}
            >
              <MenuItem value={"Online"}>
                <ListItemText primary={"Online"} />
              </MenuItem>
              <MenuItem value={"Cash"}>
                <ListItemText primary={"Cash"} />
              </MenuItem>
              <MenuItem value={"Cheque"}>
                <ListItemText primary={"Cheque"} />
              </MenuItem>
            </Select>
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              id="transactionDetails"
              size="small"
              label="Transaction Details"
              value={data?.transactionDetails}
              onChange={(e) => {
                setData({
                  ...data,
                  transactionDetails: e.target.value,
                });
              }}
            />

          </Box>
          <Button 
          sx={{
            mt: 2
          }}
                variant="contained"
                onClick={()=>{
                    setOpen(false)
                    callback(data)

                }}
                fullWidth>add</Button>
        </Box>
      </Modal>
    );
  };
  