import React, { useRef, useState } from 'react';
import axios from 'axios';
import { addDataServeice } from './uploadApi';
import { Box, Button } from '@mui/material';

const FileUpload = ({api,text}) => {
  const [file, setFile] = useState(null);

  // Handle file selection
  const handleFileChange = async(event) => {
    event.preventDefault()
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    addDataServeice(api,formData,(data)=>{
        console.log("data added",data)
    })
     
    setFile(event.target.files[0]); // Store the selected file
  };

  // Handle form submit and send API request
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      alert('Please select a file first.');
      return;
    }
 
  };
  const ref1=useRef()
  const handleIconClick = (event) => {
    event.preventDefault(); // Prevent button default behavior (reload)
    ref1.current?.click(); // Trigger hidden file input click
  };
  return (
    <div> 
      <form  >
      <input
      ref={ref1}
        type="file"
        id="imageUpload"
        style={{ display: 'none' }}
        accept=".xlsx, .xls"
        onChange={handleFileChange}
      />
        
        <Button variant="contained" onClick={handleIconClick}>{text}</Button>
      </form>
    </div>
  );
};

export default FileUpload;
