import { Box, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export const TextFieldSearch=({search,setOpen, label  })=>{
    return (
        <>
          <Box
                  // disabled
                  // size="small"
                  readonly
                  fullWidth
                  onClick={() => {
                    // setSearchOutStandingsModal(true)
                  }}
                  // id="name"
                  // label="Search by Name/Mobile Number"
                  // value={search}
                  sx={{
                    display: "flex",
                    height: "23px",
                    justifyContent: "space-between",
                    // width: "70%",
                    border: "1px solid #c9c9c9",
                    p: 2,
                    borderRadius: "4px ",
                    color: "grey",
                    "&:hover": {
                      cursor: "pointer !important",
                    },
                  }}
                  onChange={(e) => {
                    // setSearch(e.target.value);
                  }}
                >
                  <Typography variant="p">
                    {search !== ""  || !search ? search :   label?label:"Search by Name/Mobile Number"}
                  </Typography>
                  <SearchIcon
                    onClick={() => {
                      // setSearchModal(true)
                      setOpen(true);
                    }}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                  />
                  </Box></>
    )
}