import React, { useRef, useState } from "react";
import EventIcon from "@mui/icons-material/Event";

import {
  Box,
  Divider,
  Fab,
  Grid,
  Modal,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DatePicker from "react-datepicker";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "react-datepicker/dist/react-datepicker.css";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useEffect } from "react";

import { getPurchaseDetail } from "./purchaseApi";

import { displayDateFormat, formatDateFromCustomToStandard, getEndOfMonth, getFirstDateOfCurrentMonthPayloadFormate, getFirstDateOfMonth, getLastDateOfCurrentMonthPayloadFormat, getLastDateOfMonth, getStartOfMonth, payloadDateFormat } from "../../utils/commonUtils";
import PurchaseAccordian from "./purchaseAccordian";
import PuchaseModal from "./purchaseModal";
import { getPurchase } from "./purchaseCustomApiHandler";
import {
  datePickerOnChange,
  datePickerOnChangeEnd,
} from "./purchaseDateFunctions";
import { PageSizeCalculate } from "./purFunction";
import { callbackHandlerGetPurchase } from "./purchaseFunctionParamter";
import PurchaseSearchModal from "./purchaseSearchModal";
import DynamicBreadcrumbs from "../../common/Breadcrumb";

const Purchase = ({gridSize,CardComponent}) => {
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [counterSales, setCounterSales] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    records: 0,
    pageSize: 15,
  });
  const [numberOfRecords, setNumberOfRecords] = useState(1);

  const [date, setDate] = useState(getStartOfMonth());
  const [displayEndDate, setDisplayEndDate] = useState(getEndOfMonth());

  const [SelectedStartDate, setSelectedStartDate] = useState(getFirstDateOfMonth());
  const [SelectedEndDate, setSelectedEndDate] = useState(getLastDateOfMonth());
  const [startDateFormat, setstartDateFormat] = useState(getFirstDateOfCurrentMonthPayloadFormate());

  const [endDate, setEndDate] = useState(getLastDateOfCurrentMonthPayloadFormat());
  let payload = {
    rawMaterialId: 0,
    quantity: 0,
    amount: 0,
    description: "",
    price: 0,
  };
  const [purchaseItem, setPurchaseItem] = useState(payload);

  const [searchItem, setSearchItem] = useState([]);
  const datePickerRef = useRef();
  const datePickerRef2 = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  const [totalAmount, setTotalAmount] = useState(0);
  const [purchaseItems, setPurchaseItems] = useState([]);

  const callbackHandlerGetPurchaseob = callbackHandlerGetPurchase(
    getPurchase,
    {
      fromDate: startDateFormat,
      toDate: endDate,
      search: "string",
      page: 1,
      pageSize: pagination.pageSize,
    },
    setNumberOfRecords,
    setPurchaseItems,
    getPurchaseDetail,
    setTotalAmount
  )
  useEffect(() => {

    callbackHandlerGetPurchaseob.getPurchase.callback({
      ...callbackHandlerGetPurchaseob.getPurchase.parameter,
      param: {
        fromDate: startDateFormat,
        toDate: endDate,
        search: "string",
        page: 1,
        pageSize: pagination.pageSize,
      }
    }
    )
    // getPurchase(
    //   {
    //     fromDate: startDateFormat,
    //     toDate: endDate,
    //     search: "string",
    //     page: 1,
    //     pageSize: 4,
    //   },
    //   setNumberOfRecords,
    //   setPurchaseItems,
    //   getPurchaseDetail, setTotalAmount
    // );
  }, [startDateFormat, endDate]);
  useEffect(() => {
    console.log("datE ", getFirstDateOfCurrentMonthPayloadFormate());
    callbackHandlerGetPurchaseob.getPurchase.callback({
      ...callbackHandlerGetPurchaseob.getPurchase.parameter,
      param: {
        fromDate: startDateFormat,
        toDate: endDate,
        search: "string",
        page: 1,
        pageSize: pagination.pageSize,
      }
    }
    )
    // getPurchase(
    //   {
    //     fromDate: startDateFormat,
    //     toDate: endDate,
    //     search: "string",
    //     page: 1,
    //     pageSize: 4,
    //   },
    //   setNumberOfRecords,
    //   setPurchaseItems,
    //   getPurchaseDetail, setTotalAmount
    // );
  }, []);
  const handleChange = (event, value) => {
    callbackHandlerGetPurchaseob.getPurchase.callback({
      ...callbackHandlerGetPurchaseob.getPurchase.parameter,
      param: {
        fromDate: startDateFormat,
        toDate: endDate,
        search: "string",
        page: value,
        pageSize: pagination.pageSize,
      },
    }
    )
    // getPurchase(
    //   {
    //     fromDate: startDateFormat,
    //     toDate: endDate,
    //     search: "string",
    //     page: value,
    //     pageSize: 4,
    //   },
    //   setNumberOfRecords,
    //   setPurchaseItems,
    //   getPurchaseDetail,
    //   setTotalAmount
    // );
    setPagination({
      ...pagination,
      page: value,
    });
  };

  return (
    <>
      <Grid
        container
        sx={{
          p: "20px",
          display: "flex",
        }}
      >
                <DynamicBreadcrumbs/>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "56px"

          }}
        >
          <TextField
            fullWidth
            readonly
            onClick={openDatePicker}
            id="date"
            sx={{
              mt: 2,
              width: '60%'
            }}
            value={date + " To " + displayEndDate}
            label={
              <Box
                sx={{
                  flex: 4,
                  color: "grey",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                Date :
              </Box>
            }
            onChange={(e) => { }}
            InputProps={{
              style: {

                height: "40px"
              },
              endAdornment: (
                <EventIcon
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                />
              ),
            }}
          />
          {
            gridSize && (
              <FileDownloadIcon
              sx={{
                mt:"17px",
                ml: "10px",
                mr: "0px",
                height: "1.4375em",
                cursor: "pointer"
                // m: "16.5px 14px",
              }}/>
            )
          }
             
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Box>
        </Grid>

        <DatePicker
          hidden={true}
          selected={SelectedStartDate}
          maxDate={new Date()}
          onChange={(data) =>
            datePickerOnChange(
              data,
              datePickerRef2,
              setDate,
              setstartDateFormat,
              setSelectedStartDate
            )
          }
          className="custom-datepicker"
          ref={datePickerRef}
          onClickOutside={() => {
            datePickerRef.current.setOpen(false);
          }}
        />

        <DatePicker
          hidden={true}
          selected={SelectedEndDate}
          minDate={new Date()}
          onChange={(data) =>
            datePickerOnChangeEnd(
              data,
              datePickerRef2,
              setSelectedEndDate,
              setDisplayEndDate,
              setEndDate
            )
          }
          className="custom-datepicker"
          ref={datePickerRef2}
          onClickOutside={() => {
            datePickerRef2?.current?.setOpen(false);
          }}
        />

        <Grid
          item
          xs={6}
          sx={{
            mt: "10px",
            padding: "8px 16px",
            fontWeight: "500",
          }}
        >
          <Box
            component="span"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            Total Bills Amount (<CurrencyRupeeIcon sx={{ fontSize: "14px" }} />

            ):
          </Box>
          <Box
            component="span"
            sx={{
              // marginLeft: "8px",
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            {" "}
            {/* {counterSales?.totalAmount} */}
            {totalAmount?.toFixed(2)}
          </Box>

        </Grid>
       {!gridSize && <Grid item xs={6}
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: "center"
          }}

        >     <Fab
          onClick={() => {
            // setOpen(true);
            setOpen(true);
          }}
          sx={{
            textTransform: "capitalize",
            width: {
              xs: "100%",
              sm: "auto",
            },
            // p: "10px",
            whiteSpace: "nowrap",
          }}
          size="small"
          color="primary"
          variant="extended"
          aria-label="add"
        >
            Add Purchase
            <AddIcon />
          </Fab></Grid>}

        <Divider
          sx={{
            width: "100%",
            mt: 2,
            background: (theme) => theme.palette.primary.main,
          }}
        />

        <Grid container>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "block",
              },
            }}
          ></Grid>
          <Grid item xs={12} sx={{ p: "10px" }}></Grid>
          {purchaseItems?.map((item, i) => {
            return (
              <Grid
                item
                xs={gridSize?(gridSize.xs):12}
                


                sm={gridSize?(gridSize.sm):4}
                
                // md={12}
                // lg={6}
                key={"payamentRecpt" + i}
                sx={{ p: "10px", borderRight: "3px solid #dfdfdf" }}
              >
                <PurchaseAccordian item={item} />
              </Grid>
            );
          })}
        </Grid>
        <Grid container sx={{ pt: "10px" }}>
          {/* <Grid item xs={4}>
            <Typography color="primary">
              <strong>Total Items: &nbsp; {numberOfRecords}</strong>
            </Typography>
          </Grid> */}
          <Grid
            item
            xs={12}
            sx={{
              // bgcolor: "red",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <Stack direction="row" justifyContent="space-around" sx={{ mt: 2 }}> */}
            <Pagination
              count={PageSizeCalculate(numberOfRecords, pagination)}
              page={pagination.page}
              onChange={handleChange}
            />
            {/* </Stack> */}
          </Grid>
          {/* <Grid item xs={4}></Grid> */}
        </Grid>

        <PuchaseModal
          open={open}
          setOpen={setOpen}
          getPurchase={() => {
            callbackHandlerGetPurchaseob.getPurchase.callback({
              ...callbackHandlerGetPurchaseob.getPurchase.parameter,
              param: {
                fromDate: startDateFormat,
                toDate: endDate,
                search: "string",
                page: pagination.page,
                pageSize: pagination.pageSize,
              }
            }
            )


          }}


          searchItem={searchItem}
          setSearchItem={setSearchItem}
          purchaseItem={purchaseItem}
          setPurchaseItem={setPurchaseItem}
          payload={payload}
          search={search}
          setSearch={setSearch}

          openSearch={openSearch}
          setOpenSearch={setOpenSearch}
        />
        <PurchaseSearchModal
          openSearch={openSearch}
          setOpenSearch={setOpenSearch}
          searchItem={searchItem}
          purchaseItem={purchaseItem}
          setPurchaseItem={setPurchaseItem}
          search={search}
          setSearch={setSearch}
        />
        {/* <AddCircleIcon
          color="primary"
          onClick={() => {
            setOpen(true);
          }}
          sx={{
            fontSize: "60px",
            cursor: "pointer",
            position: "fixed",
            bottom: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        /> */}
      </Grid>
    </>
  );
};

export default Purchase;
