import {
    Card,
    CardContent,
    Grid,
    Stack,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React from "react";
  import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee"; 
import { convertDateToCustomFormat } from "../../utils/commonUtils";
  export default function CardEle({ item,callback ,cbt}) {
    return (
      <Card
      onClick={()=>{
        callback(item)
      }}
        sx={{
          width: "100%",
          mt: "10px",
          cursor: "pointer",
          ":hover": {
            bgcolor: "#2892ba17",
          },
          "& .MuiCardContent-root": { p: "0rem" }
        }}
        


      >
        <CardContent sx={{ padding: "0rem 0.7rem !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                // color="primary"
                sx={{
                    textAlign:'left',
                  fontSize: "15px",
                  overflow: "Hidden",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  fontWeight: 600,
                }}
              >
                {item?.offerInformation}
              </Typography>
            </Grid>
  
            <Grid item xs={12}>
              <Typography
                color="primary"
                sx={{
                    textAlign:'right',
                  fontSize: "15px",
                  overflow: "Hidden",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  fontWeight: 600,
                }}
                onClick={(e)=>{
                  e.stopPropagation();
                  cbt(item)
                  console.log('clicked')
                }}
              > 

              Click Here To Send!
              </Typography>
            </Grid>
  
           
          </Grid>
        </CardContent>
      </Card>
    );
  }
  