import { useSelector } from "react-redux";
import { swalAlertPopProps } from "../../utils/commonUtils";

export const discountedAmount = (bill) => {
  if (bill.discountType === "Percent") {
    return (bill.discount / 100) * bill.amount?.toFixed(2);
  }
  if (bill.discountType !== "Percent") {
    return bill.discount;
  }
};

export const addProductInFireBase = async (
  currentDataArr,
  setAddToCardWindow,
  tableOrderInFirebase,
  setAddToCard,
  item,
  fireDb,
  userDetail,
  tableName,
  setBill,
  bill,
  setCurrentDataArr,
  qtySet,
  addToCard
) => {
  let ind = currentDataArr.findIndex((x) => x[1].id === item.id);

  setAddToCardWindow(true);
  if (ind === -1) {
    tableOrderInFirebase(
      item,
      fireDb,
      userDetail,
      tableName,
      setBill,
      bill,
      setCurrentDataArr
    );
    let arr = [...addToCard];
    console.log("added item", item);
    arr.push(item);
    setAddToCard(arr);
  } else {
    qtySet(
      "+",
      null,
      item?.fcmRowId,
      fireDb,
      userDetail,
      tableName,
      setCurrentDataArr
    );
  }
};

export const ApplyAccordingREAndCBUser = (userDetail, ReUser, Cbuser) => {
  if (userDetail?.businessLineCode === "CB") {
    return Cbuser();
  }
  return ReUser();
};

export const calCulatePriceOfCBUsersProduct = (
  addtoCardProductCBUser,
  bill,
  setBill,
  setSaveClick
) => {
  let sum = 0;
  let priceWithoutGst = 0;
  let sumOfGst = 0;
  let sumOfsgst=0
  let sumOfcgst=0
  let sumOfigst=0
  
  let arr = [...addtoCardProductCBUser];
  let arrVisualization={
    gst:{arr:[],ans:0},
    priceWithoutGst:{arr:[],ans:0},
    paybill:{arr:[],ans:0},
    
  };
  


  console.log("arrarr=== ",arr)
  arr.forEach((item) => {
    sum += item.price * item.suppliedQuantity;
    priceWithoutGst += Number(item.currentPrice * item.suppliedQuantity);
    // arr.push({ans:priceWithoutGst,value1:item.currentPrice,value2:item.suppliedQuantity,operation:'*'})
    
    sumOfGst += Number(item.gst);
    sumOfsgst += Number(item.sgst);
    sumOfigst += Number(item.igst);
    sumOfcgst += Number(item.cgst);
    arrVisualization.priceWithoutGst.arr.push({
      currentPrice: item.currentPrice,
      suppliedQuantity:item.suppliedQuantity,
      
    })
    arrVisualization.paybill.arr.push({
      price: item.price,
      suppliedQuantity:item.suppliedQuantity,
       

    })
  });

  
  arrVisualization.paybill.ans=sum
  arrVisualization.priceWithoutGst.ans=priceWithoutGst; 


   console.log("arr d===",arr)
  setBill((prev)=>{
    let prevData={...prev}
    // prevData.amount=sum
    // prevData.paybill=sum
    // prevData.discount=0
    // prevData.sumOfGst=sumOfGst
    // prevData.priceWithoutGst=priceWithoutGst

    return prevData
  })
  // setBill({ ...bill, amount: sum, paybill: sum, discount: 0 });
  setSaveClick(false);
};

export const calCulatePriceOfREUsersProduct = (
  currentDataArr,
  addtoCardProductCBUser,
  bill,
  setBill,
  setSaveClick,
  fn
) => {
  const arr1 = currentDataArr !== null ? [...currentDataArr] : [];
  let sum = 0;
  let arrVisualization={
   arr:[],
   ans:0

    
  };
  console.log("aarr1 priceWithoutGst",arr1)
  arr1?.forEach((item) => {
    sum += item[1].price * item[1].suppliedQuantity;


    arrVisualization.arr.push({
      sgst:item[1].sgst,
      cgst:item[1].cgst,
      igst:item[1].igst,      
      itemDescription:item[1].itemDescription,
      currentPrice: item[1].price,
      suppliedQuantity: item[1].suppliedQuantity,
      gst:item[1].gst
      
    })
    
  });
  arrVisualization.ans=sum

  console.log("billp am ", sum);
  
  setBill((prev)=>{
    let prevData={...prev}
    // prevData.amount=sum
    // prevData.paybill=sum
    // prevData.discount=0
    return prevData
  })
  if(fn){ 
  fn(arrVisualization)

  }
    
  // setBill({ ...bill, amount: sum, paybill: sum, discount: 0 });
  setSaveClick(false);
};

const checkItemIsAllreadyExist = (arr, item) => {
  let ind = arr.findIndex((a) => a.id == item);
  return {
    status: ind !== -1 ? true : false,
    index: arr.findIndex((a) => a.id == item),
  };
};
export const addProductInAddtoCartCBUserFn = (
  item,
  addtoCardProductCBUser,
  setAddtoCardProductCBUser
) => {
  let checkItem = checkItemIsAllreadyExist(addtoCardProductCBUser, item?.id);
  if (!checkItem.status) {
    let obj = { ...item };
    obj["suppliedQuantity"] = 1;
    obj["printQty"] = 1;
    obj.price = item.price + item.price * (item.gst / 100);
    setAddtoCardProductCBUser([...addtoCardProductCBUser, obj]);
  } else {
    let arr = [...addtoCardProductCBUser];
    console.log(arr, checkItem.index, "adadat");
    arr[checkItem.index].suppliedQuantity += 1;
    arr[checkItem.index].printQty += 1;
    setAddtoCardProductCBUser(arr);
  }
};

export const deleteCBUserProduct = (
  addtoCardProductCBUser,
  setAddtoCardProductCBUser,
  index
) => {
  let arr = [...addtoCardProductCBUser];
  arr.splice(index, 1);
  setAddtoCardProductCBUser(arr);
};

export const addQtyCBUser = (
  index,
  addtoCardProductCBUser,
  setAddtoCardProductCBUser
) => {
  let arr = [...addtoCardProductCBUser];
  arr[index].suppliedQuantity += 1;
  arr[index].printQty += 1;
  setAddtoCardProductCBUser(arr);
};
export const setQtyCbUser = (
  index,
  addtoCardProductCBUser,
  setAddtoCardProductCBUser,
  qty
) => {
  console.log(" qty index", index);
  let arr = [...addtoCardProductCBUser];
  arr[index].suppliedQuantity = qty;
  arr[index].printQty = qty;
  setAddtoCardProductCBUser(arr);
};
export const subQtyCBUser = (
  index,
  addtoCardProductCBUser,
  setAddtoCardProductCBUser
) => {
  let arr = [...addtoCardProductCBUser];
  if (arr[index].suppliedQuantity > 1) {
    arr[index].suppliedQuantity -= 1;
    arr[index].printQty -= 1;
  } else {
    arr.splice(index, 1);
  }
  setAddtoCardProductCBUser(arr);
};

export const handleRenderingSnacBar = (
  renderPage,
  setRenderPaage,
  setAddToCardWindow,
  addToCardWindow
) => {
  setRenderPaage(true);
  if (renderPage === false) {
    setAddToCardWindow(true);
  } else {
    setAddToCardWindow(!addToCardWindow);
  }
};

export const printBillByPrinter = (
  creditValidtionCheck,
  bill,
  userDetail,
  Swal,
  saveBill,
  saveBillCbUser
) => {
  let valid = creditValidtionCheck(bill);
  if (valid.status === false) {
    Swal.fire({
      text: valid.msg,
      icon: "warning",
      ...swalAlertPopProps,
    });
  } else {
    ApplyAccordingREAndCBUser(
      userDetail,
      () => {
        return saveBill();
      },
      () => {
        return saveBillCbUser();
      }
    );
  }
};

export const ConditionalNameCbUserSetter = (bill) => {
  // bill.paymentMode === "Credit" ? bill.customer : tableName;
  if (bill.paymentMode === "Credit") {
    return bill.customer;
  }
  if (bill.paymentMode === "Cash") {
    return "Cash bill";
  }
  if (bill.paymentMode === "Digital") {
    return "Online Bill";
  }
};

export const ConditionalNameReUserSetter = (bill, tableName) => {
  // bill.paymentMode === "Credit" ? bill.customer : tableName;
  return bill.paymentMode === "Credit" ? bill.customer : tableName;
};

export const savePrintBill = (
  bill,
  creditValidtionCheck,
  Swal,
  userDetail,
  saveBill,
  saveBillCbUser,
  billNumberCB
) => {
  let valid = creditValidtionCheck(bill);
  if (valid.status === false) {
    Swal.fire({
      text: valid.msg,
      icon: "warning",
      ...swalAlertPopProps,
    });
    return false;
  } else {
    ApplyAccordingREAndCBUser(
      userDetail,
      () => {
        return saveBill();
      },
      () => {
        return saveBillCbUser();
      }
    );
    return true;
  }
};

export const resetAddToCart = (
  setAddtoCardProductCBUser,
  setBill,
  setbillData,
  setAddToCardWindow
) => {
  setAddtoCardProductCBUser([]);
  // console.log("billp am ", sum);
  setBill(setbillData);
  setAddToCardWindow(false);
};
