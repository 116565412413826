import { Box, Grid, Tab, Tabs, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { getAllCustomerDetail } from "../../common/searchModal/api"
import OutStandingReportCart from "../employeePayRoll/outStandingReportCart"
import CardCounterInformation from "./card"
import SearchIcon from "@mui/icons-material/Search";
import SearchModalCustomer from "../../common/searchModal/customer"
import { getData } from "./counterInformationApi"
import { CustomeDetail } from "../customerDetail/customerDetail"
import LastCustomer from "../../common/searchModal/lastCustomer"
import CustomerInfo from "./CustomerInfo"
import DynamicBreadcrumbs from "../../common/Breadcrumb"
import { useSelector } from "react-redux"

export const CustomerInformation = () => {
  const [collection, setCollection] = useState([])
  const [search, setSearch] = useState("");
  const [searchOutStandingsModal, setSearchOutStandingsModal] = useState(false)

  const [openT, setOpenT] = useState(false)
  const [selectedData, setSeletecData] = useState(null)
  const [value, setValue] = useState("all");
  const businessChooseTab=useSelector((state)=>state.reducer.businessChooseTab)

  const handleChange = (event, newValue) => {
    // setPageNo(1);

    setValue(newValue);
  };

  useEffect(() => {

  }, [])
  return (
<>
<DynamicBreadcrumbs />
<Box sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}>
<Grid
      container

      spacing={1}
      sx={{
        padding: '20px'
      }}
    >
      <Grid item xs={12}>
         
      </Grid>
      {
            businessChooseTab?.value  === "customerhistory" && (
         <CustomerInfo/>
        )
      }
      { businessChooseTab?.value !=="customerhistory" && (
        <CustomeDetail />
      )}
    </Grid>
</Box>
</>

  )
}