import { Box, Button, Typography, Modal, Grid, Divider } from "@mui/material";

import React, { useEffect, useState } from "react"; 
import { useNavigate } from "react-router"; 
import CloseIcon from "@mui/icons-material/Close";

import { useDispatch, useSelector } from "react-redux";
import { setAppoinmentType } from "../app/store/reducer";
import { routePath } from "../constants/routePath";
 

const ApModal = ({ open, setOpen, setFilterBy }) => {
  const [CollectionTableItems, setCollectionTableItems] = useState([]);
  

  const [indexTableItem, setIndexTableItem] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateRoute=(e)=>{
    dispatch(setAppoinmentType(e.target.id))
    if(e.target.id=="profile"){
        navigate(routePath.LOGIN)
    }
    else{
        navigate(routePath.APPOINMENT)
    }
  }
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          padding:'20px',
          width: {
            xs: "90vw",
            sm: "50vw",
            lg:'30vw'
          },
          borderRadius: "10px",
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.main,
                textAlign: "center",
                fontSize: "24px",
              }}
            >
              {" "}
              Book New Appointment  <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => setOpen(false)} />
            </Typography>
            <Divider/>
            <Box display={'flex'} mt={3} alignItems={'center'} justifyContent={'center'} gap={1}>
            <Button variant="contained" id='profile' onClick={navigateRoute}>Profile Login </Button>
            <Typography
            >Or
            </Typography>
            <Button variant="contained" id='otp' onClick={navigateRoute}>OTP Verification</Button>
            </Box>
      </Box>
    </Modal>
  );
};
export default ApModal;
