import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Stack,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { convertDateToCustomFormat } from "../../utils/commonUtils";

export default function OutStandingSalaryReportCard({ item, setOpen, setModalData }) {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleAccordionChange}
      sx={{
        width: "100%",
        ":hover": {
          bgcolor: "#2892ba17",
        },
        cursor: "pointer",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={() => {
          if (!setOpen) {
            setOpen(true);
          }
          setModalData(item);
        }}
      >
        <Grid container>
          <Grid item xs={4}>
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                fontWeight: 600,
              }}
            >
              {convertDateToCustomFormat(item?.createdDate?.split("T")[0])}
            </Typography>
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
              }}
            >
              {convertDateToCustomFormat(item?.fromDate?.split("T")[0])}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="primary" sx={{ fontSize: "14px" }}>
              {item?.emaployeeName}
            </Typography>
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                fontWeight: 600,
              }}
            >
              {convertDateToCustomFormat(item?.toDate?.split("T")[0])}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {item?.customerMobile}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <CurrencyRupeeIcon color="secondary" fontSize="10px" />
              <Typography
                color="secondary"
                sx={{
                  fontSize: "15px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  fontWeight: 600,
                }}
              >
                {item?.amount?.toFixed(2)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        
        <Grid container spacing={2}>
        {item?.paymentMethod &&   <Grid item xs={12}
           
           sx={{display:'flex',alignItem:'center',
          justifyContent:'space-between'}}
            >
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                
                padding:"0px",
              }}
            >
              Payment Mode
            </Typography>
            <Typography
              
              sx={{
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
              }}
            >
              
              {item?.paymentMethod}
            </Typography>
           
          </Grid>
          
        }
         

 
{item?.transactionDetails &&
<>
          <Grid item xs={12}      
           sx={{display:'flex',alignItem:'center',
          justifyContent:'space-between'}}
            >
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                fontWeight: 600,
              }}
            >
              Transaction No./  Cheque No.
            </Typography>
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
              }}
            >
              
              {item?.transactionDetails}
            </Typography>
          </Grid>
          
 
          </>
           }
        </Grid>
        <Grid xs={12} sx={{
          display:'flex',
          alignItems:'center',
          justifyContent:'space-around'
        }}>
          <Button variant="contained" sx={{margin:"0px 10px"}}>View Salary Slip</Button>
        <Button variant="contained" sx={{margin:"0px 10px"}}>View Salary Slip</Button>

        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
