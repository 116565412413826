import { Box, Grid, Typography } from "@mui/material";
import { routePath } from "../../constants/routePath";

export const PopEmployee = ({ sectionPresent, tables, dispatch, navigate, bookedTables, section,setTableName }) => {
  
  const handleTableClick = (tableName) => {
    dispatch(setTableName(tableName));
    navigate(routePath.COUNTERSELLBILLING);
  };

  return (
    <Grid container>
      {!sectionPresent && (
        <Grid
          item
          xs={12}
          sx={{
            overflowY: "auto",
            maxHeight: {
              xs: "calc(100vh - 60px)",
              sm: "calc(100vh - 60px)",
            },
          }}
        >
          {tables.map((item2, index) => (
            <Box
              key={"tableListBill" + index}
              sx={{
                display: "inline-block",
                m: "10px 0px",
              }}
            >
              <Box
                onClick={() => handleTableClick(item2.name)}
                sx={{
                  margin: "0px 10px",
                  cursor: "pointer",
                  boxShadow:
                    bookedTables?.includes(item2.name)
                      ? "0 4px 8px rgb(255 33 33 / 81%)"
                      : "0 4px 8px rgb(27 87 21)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100px",
                  p: "0px",
                  fontWeight: "bold",
                  textAlign: "center",
                  height: "60px",
                  borderRadius: "10px",
                }}
              >
                {item2.name}
              </Box>
            </Box>
          ))}
        </Grid>
      )}

      {sectionPresent && (
        <Grid
          container
          sx={{
            overflowY: "auto",
            maxHeight: {
              xs: "100vh",
              sm: "calc(100vh - 60px)",
            },
          }}
        >
          {section.map((item, index) => {
            const sectionTables = tables.filter(table => table.sectionId === item.id);

            if (sectionTables.length === 0) return null;

            return (
              <Grid item xs={12} key={"tablesection" + index} sx={{ p: "10px" }}>
                <Typography variant="h6" gutterBottom align="left">
                  {item.name}
                </Typography>
                <Grid item xs={12}>
                  {sectionTables.map((item2, index) => (
                    <Box
                      key={"tableListBill" + index}
                      sx={{
                        display: "inline-block",
                        m: "10px 0px",
                      }}
                    >
                      <Box
                        onClick={() => handleTableClick(item2.name)}
                        sx={{
                          margin: "0px 10px",
                          cursor: "pointer",
                          boxShadow:
                            bookedTables?.includes(item2.name)
                              ? "0 4px 8px rgb(255 33 33 / 81%)"
                              : "0 4px 8px rgb(27 87 21)",
                          background:
                            bookedTables?.includes(item2.name)
                              ? "radial-gradient(circle, rgba(238,0,0,1) 0%, rgba(171,0,0,1) 100%)"
                              : "radial-gradient(circle, rgba(0,189,57,1) 0%, rgba(2,87,50,1) 100%)",
                          color: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "60px",
                          width: "100px",
                          p: "0px",
                          fontWeight: "bold",
                          borderRadius: "10px",
                        }}
                      >
                        {item2.name}
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};
